import React, { Component } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import nonAvatar from "../../assets/image/non-avatar.png";
import { getAllResults } from "../../actions/searchActions";
import ShopPageCard from "../ShopPage/ShopPageCard";
import { getStorages, makeRequest } from "../../actions/productsActions";
import FilterComponent from "../HelperComponents/FilterComponent";
import SortComponent from "../HelperComponents/SortComponent";
import "./SearchPage.scss";

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: props.match.params.searchValue,
            loading: true,
            filter: [],
            sort: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.searchValue !== this.props.match.params.searchValue) {
            this.setState({ searchValue: this.props.match.params.searchValue, loading: true });
        }
        if (prevState.searchValue !== this.state.searchValue) {
            this.search(this.state.searchValue);
        }
        if (prevProps.searchResult !== this.props.searchResult) {
            this.setState({ loading: false });
        }

        // Filter & sorting
        const { filter, sort, searchValue } = this.state;
        if (prevState.filter !== filter || prevState.sort !== sort) {
            let filterQuery = "";
            let sortQuery = "";
            if (filter.length > 0) filterQuery = `&filter=${filter.join(",")}`;
            if (sort.length > 0) sortQuery = `?ordering=${sort}`;

            if (filter.length > 0 || sort.length > 0) {
                this.setLoading();
                this.search(searchValue, `${sortQuery}${filterQuery}`);
            } else {
                this.setLoading();
                this.search(searchValue);
            }
        }
    }

    search = (value, query) => {
        const { getAllResults } = this.props;

        let filterSortQuery = "";
        if (query && query.length > 0) filterSortQuery = query;

        getAllResults(value, filterSortQuery);
    };

    goToProd = slug => {
        const { history } = this.props;
        history.push(`/main/shop/product/${slug}`);
    };

    goToCat = relative_url => {
        const { history } = this.props;
        history.push(`/main/shop?cat=${relative_url}`);
    };

    // Filter & sorting
    setLoading = () => this.setState({ loading: true });
    handleFilter = (values) => this.setState({ filter: values });
    handleSort = (value) => this.setState({ sort: value });

    render() {
        const { searchResult, getStorages } = this.props;
        const { loading, searchValue } = this.state;

        return (
            <TransitionedBlock classes="transitioned_block">

                <div className="page_wrapper shop_page">
                    <div className="shop_content">
                        <header className="content_header">
                            <div className="title_page">Результат поиска по "{searchValue}"</div>
                            <FilterComponent handleFilter={this.handleFilter} reset={searchValue} />
                            <SortComponent handleSort={this.handleSort} reset={searchValue} />
                        </header>
                        <div className="catalog_product">
                            {loading ? <p>Идет загрузка...</p> :
                                searchResult.products.length > 0
                                    ? searchResult.products.map((el, idx) => {
                                        let sum = 0,
                                            fullText = false;
                                        el.storage_quantity &&
                                        el.storage_quantity.forEach(item => {
                                            sum += item.quantity ? item.quantity : 0;
                                        });
                                        return (
                                            <ShopPageCard
                                                key={idx}
                                                el={el}
                                                idx={idx}
                                                sum={sum}
                                                goToProd={this.goToProd}
                                                getStorages={getStorages}
                                                productsStoragesName={"search"}
                                                makeRequest={makeRequest}
                                            />
                                        );
                                    })
                                    :
                                    <span className={`no_search_items`}>По Вашему запросу товаров не найдено</span>
                            }
                        </div>
                        <div className="search_page">
                            <div className="search_info">
                                <div className="wrapper_scroll">
                                    <div className="info">
                                        <p>Категории</p>
                                        <div>
                                            {loading ? <span>Идет загрузка...</span> :
                                                searchResult.categories.length > 0 ?
                                                    searchResult.categories.map((el, idx) => (
                                                        <span onClick={() => this.goToCat(el.id)} key={idx}>
                                                            <img
                                                                className={`cat_img`}
                                                                src={el.image || nonAvatar}
                                                                alt="cat_pic"
                                                            />
                                                            <p>{el.name}</p>
                                                        </span>
                                                    ))
                                                    :
                                                    <span className={`no_search_items`}>Категорий нет</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ search }) => {
    return {
        searchResult: search.search_all_result
    };
};

const mapDispatchToProps = {
    getAllResults,
    getStorages
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
