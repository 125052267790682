import { PRODUCTS } from "../constants";

export function getPopularProducts() {
    return {
        type: PRODUCTS.GET_POPULAR_PRODUCTS,
        payload: {
            client: "default",
            request: {
                url: `/tablet/products/popularity/`,
                method: "GET"
            }
        }
    };
}

export function getSpecialProducts() {
    return {
        type: PRODUCTS.GET_SPECIAL_PRODUCTS,
        payload: {
            client: "default",
            request: {
                url: `/tablet/products/promotional/`,
                method: "GET"
            }
        }
    };
}

export function getCertainProduct(slug) {
    return {
        type: PRODUCTS.GET_CERTAIN_PRODUCT,
        payload: {
            client: "default",
            request: {
                url: `/tablet/products/${slug}/`,
                method: "GET"
            }
        }
    };
}

export function getRelatedProducts(slug) {
    return {
        type: PRODUCTS.GET_RELATED_PRODUCTS,
        payload: {
            client: "default",
            request: {
                url: `/tablet/products/${slug}/related/`,
                method: "GET"
            }
        }
    };
}

export function getStorages(id, productsStoragesName) {
    let main = "";
    switch (productsStoragesName) {
        case "products_results":
            main = "CATEGORIES";
            break;
        case "search":
            main = "SEARCH";
            break;
        default:
            main = "PRODUCTS";
            break;
    }
    return {
        type: `${main}_GET_STORAGES`,
        payload: {
            client: "default",
            request: {
                url: `/tablet/product/${id}/storages/`,
                method: "GET",
                productId: id,
                productsStoragesName: productsStoragesName
            }
        }
    };
}

export function makeRequest(data, id) {
    return {
        type: PRODUCTS.MAKE_REQUEST,
        payload: {
            client: "default",
            request: {
                url: `/tablet/product/${id}/request/`,
                method: "POST",
                data
            }
        }
    };
}

export function setAdjustment(productId, storageId, quantity) {
    return {
        type: PRODUCTS.SET_ADJUSTMENT,
        payload: {
            request: {
                url: `/tablet/product/${productId}/storage/quantity/adjustment/`,
                method: "post",
                data: {
                    storage: storageId,
                    quantity: quantity
                }
            }
        }
    };
}
