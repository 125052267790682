import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import Rating from "../../HelperComponents/Rating/Rating";
import ShopPageCard from "../../ShopPage/ShopPageCard";
import { getPopularProducts, getStorages, getSpecialProducts, makeRequest } from "../../../actions/productsActions";

import checkmark from "../../../assets/image/checkmark.svg";
import pre_order from "../../../assets/image/pre_order.svg";
import Discount from "../../../assets/image/Discount.svg";
import Season from "../../../assets/image/Season.svg";
import Stale from "../../../assets/image/Stale.svg";
import "./BuyTogether.scss";
import nonAvatart from "../../../assets/image/non-avatar.png";

class BuyTogether extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    goToProd = slug => {
        const { push } = this.props;

        push(`/main/shop/product/${slug}`);
    };

    render() {
        const { relatedProducts, getStorages, makeRequest } = this.props;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="buy_together">
                    <div className="block_product">
                        {relatedProducts.length > 0 ? (
                            relatedProducts.map((el, idx) => {
                                {
                                    let sum = 0,
                                        fullText = false;
                                    el.storage_quantity &&
                                        el.storage_quantity.forEach(item => {
                                            sum += item.quantity ? item.quantity : 0;
                                        });
                                    return (
                                        <ShopPageCard
                                            el={el}
                                            idx={idx}
                                            sum={sum}
                                            goToProd={this.goToProd}
                                            getStorages={getStorages}
                                            productsStoragesName={"products_results"}
                                            makeRequest={makeRequest}
                                        />
                                    );
                                }
                            })
                        ) : (
                            <p>Товаров нет</p>
                        )}
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = { getStorages, makeRequest };

export default connect(mapStateToProps, mapDispatchToProps)(BuyTogether);
