import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import nonAvatart from "../../assets/image/non-avatar.png";
import cartCloseBtn from "../../assets/image/cart_close_btn.svg";
import cartStockWarning from "../../assets/image/cart_stock_warning.svg";
import cartPlus from "../../assets/image/plus.svg";
import cartMinus from "../../assets/image/minus.svg";
import Discount_edit from "../../assets/image/discount_edit.svg";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import { Link, NavLink } from "react-router-dom";
import saleBadge from "../../assets/image/sale-badge.svg";
import topSalesBadge from "../../assets/image/top-sales.svg";
import {
    GetClientInfo,
    sessionEnd,
    setNeedDelivery,
    setAddress,
    setDate,
} from "../../actions/clientAuthActions";
import { cartUpdate } from "../../actions/clientAuthActions";
import { toFixedNoRounding } from "../../helpers/functions";

class CartDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            buttonLoad: localStorage.getItem("buttonLoad") || "false",
            consultationStatus: localStorage.getItem("consultationStatus") || "finish",
            cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            discountModal: false,
            is_logged: localStorage.getItem("is_logged"),
            clientInfo: JSON.parse(localStorage.getItem("client_info")),
            loading: false,
            discount_size: JSON.parse(localStorage.getItem("client_info"))
                ? JSON.parse(localStorage.getItem("client_info")).user_discount
                : null,
            quantityModal: false,
            quantityModalTarget: {},
            number_order: "",
            areYouSure: false
        };
    }

    componentDidMount() {
        const { GetClientInfo } = this.props;
        GetClientInfo(localStorage.getItem("client_phone")).then(res => {});
    }

    componentDidUpdate(prevProps, prevState) {
        const { cartUpdate } = this.props;
        if (prevState.cart !== this.state.cart) {
            localStorage.setItem("cart", JSON.stringify(this.state.cart));
            cartUpdate();
        }
    }

    openQuantityModal = element => {
        this.setState({
            quantityModalTarget: element,
            quantityModal: true
        });
    };

    handlePurchaseValue = (sign, element) => {
        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            newElementAmount = Number(element.amount),
            index = cart.indexOf(cart.find(el => el.item.id === element.item.id));

        if (sign === "+") {
            newElementAmount += 1;
        } else {
            newElementAmount -= 1;
        }

        cart[index].amount = newElementAmount;
        this.setState({ cart: cart });
    };

    handleChangeInputValue = (value, element, outOfStock) => {
        let customValue,
            cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            index = cart.indexOf(cart.find(el => el.item.id === element.item.id));
        if (value == "" || value == " ") {
            customValue = "";
        } /* else if (Number(value) > 999) {
            customValue = 999;
        } */ else {
            customValue = Number(value);
        }
        if (Number(value) >= 0) {
            if (outOfStock) {
                cart[index].amount = cart[index].stock + customValue;
            } else {
                cart[index].amount = customValue;
            }
            this.setState({ cart: cart, quantityModalTarget: cart[index] });
        }
    };

    deleteCartItem = event => {
        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            type = event.target.dataset.type,
            id = event.target.dataset.id,
            target = cart.find(el => el.item.id == id),
            index = cart.indexOf(cart.find(el => el.item.id == id));
        switch (type) {
            case "all":
                cart = cart
                    .map(el =>
                        el.item.has_id_1c === false
                            // el.stock == 0
                            ? null
                            : el.amount > el.stock
                            ? {
                                ...el,
                                amount: el.stock
                            }
                            : el
                    )
                    .filter(el => el !== null);
                break;
            case "in":
                cart = [...cart.slice(0, index), ...cart.slice(index + 1)];
                break;
            case "out":
                if (target.item.has_id_1c === false ) {
                    cart = [...cart.slice(0, index), ...cart.slice(index + 1)];
                } else {
                    cart[index].amount = cart[index].stock;
                }
                break;
            default:
                break;
        }
        this.setState({ cart: cart });
    };


    render() {
        const {cart,  quantityModal, quantityModalTarget, } = this.state;
        const { handleSubmit, el, sum} = this.props;

        return (
            <div className="client_page with_cart">
                <div
                    className={`client_content client_info cart${
                        cart === null
                            ? ""
                            : cart.length > 0 &&
                            cart.some(searchedElement => searchedElement.stock !== 0)
                            ? ""
                            : " empty"
                    }`}
                >
                    <div>
                        <div className="name">Товары в наличии</div>
                        {/* {cart !== null && cart.inStock.length > 0 && <button className="clear_cart">Очистить корзину</button>} */}
                    </div>
                    {cart !== null &&
                    cart.length > 0 &&
                    cart.some(searchedElement => searchedElement.stock !== 0) ? (
                        <div className="cart-item">
                            {cart
                                .filter(searchedElement => searchedElement.stock !== 0)
                                .map((el, idx) => {
                                    return (
                                        <>
                                            {el.item.has_id_1c === true ?
                                                <div key={idx} className="cart-item container">
                                                    <div className="image-wrapper">
                                                        <img
                                                            src={
                                                                el.item.images.length > 0
                                                                    ? el.item.images[0]
                                                                    : nonAvatart
                                                            }
                                                            alt="Cart item image"
                                                        />
                                                    </div>
                                                    <div className="info-wrapper">
                                                        <div className="info header">
                                                            <Link
                                                                to={`/main/shop/product/${el.item.id}`}
                                                                className="heading"
                                                            >
                                                                {el.item.name}
                                                            </Link>
                                                            <img
                                                                className="delete"
                                                                src={cartCloseBtn}
                                                                alt="Delete cart item"
                                                                data-type="in"
                                                                data-id={el.item.id}
                                                                onClick={this.deleteCartItem}
                                                            />
                                                        </div>
                                                        <div className="info body">
                                                            <div className="input_add">
                                                                <img
                                                                    src={cartMinus}
                                                                    alt="cartminus"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        el.amount > 1 &&
                                                                        this.handlePurchaseValue(
                                                                            "-",
                                                                            el,
                                                                            true
                                                                        );
                                                                    }}
                                                                    style={
                                                                        el.amount <= 1 ? { opacity: "0.3" } : {}
                                                                    }
                                                                    className="cart-minus"
                                                                />
                                                                <input
                                                                    value={
                                                                        el.amount === 0
                                                                            ? ""
                                                                            : el.amount > el.stock
                                                                            ? el.stock
                                                                            : el.amount
                                                                    }
                                                                    onChange={e => {
                                                                        this.handleChangeInputValue(
                                                                            e.target.value,
                                                                            el,
                                                                            false
                                                                        );
                                                                    }}
                                                                    onClick={e => e.stopPropagation()}
                                                                    disabled
                                                                />
                                                                <img
                                                                    src={cartPlus}
                                                                    alt="cartplus"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        /* el.amount < 999 && */
                                                                        this.handlePurchaseValue("+", el, true);
                                                                    }}
                                                                    className="cart-plus"
                                                                    /* style={
                                                                el.amount >= 999 ? { opacity: "0.3" } : {}
                                                            } */
                                                                />
                                                                <img
                                                                    src={Discount_edit}
                                                                    className="edit_btn"
                                                                    alt=""
                                                                    onClick={() => this.openQuantityModal(el)}
                                                                />
                                                            </div>
                                                            <div className="price">
                                                                {(el.item.discount_price || !!el.discount) && (
                                                                    <span className="discount">
                                                                                {toFixedNoRounding(
                                                                                    el.item.retail_price,
                                                                                    2
                                                                                )}{" "}
                                                                        грн
                                                                            </span>
                                                                )}
                                                                <span>
                                                                            {toFixedNoRounding(
                                                                                el.item.discount_price || !!el.discount
                                                                                    ? !!el.discount
                                                                                    ? el.item.retail_price -
                                                                                    (el.item.retail_price *
                                                                                        el.discount) /
                                                                                    100
                                                                                    : el.item.discount_price
                                                                                    : el.item.retail_price,
                                                                                2
                                                                            )}{" "}
                                                                    грн
                                                                        </span>
                                                                {el.item.sale ? (
                                                                    <img
                                                                        src={saleBadge}
                                                                        alt="sale"
                                                                        style={{ marginLeft: "40px" }}
                                                                    />
                                                                ) : el.item.top_sales ? (
                                                                    <img
                                                                        src={topSalesBadge}
                                                                        alt="topSales"
                                                                        style={{ marginLeft: "40px" }}
                                                                    />
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        {el.amount > el.stock && (
                                                            <div className="info footer">
                                                                <img
                                                                    src={cartStockWarning}
                                                                    alt="Cart stock warning"
                                                                />
                                                                <span>
                                                                            Только {el.stock} в наличии на складе.{" "}
                                                                    {el.amount - el.stock} - под заказ!
                                                                        </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                : null}
                                        </>
                                    );
                                })}
                        </div>
                    ) : (
                        <p>Нет товаров.</p>
                    )}
                </div>
                {cart.filter(searchedElement =>
                        searchedElement.item.has_id_1c === false ||
                        (searchedElement.amount > searchedElement.stock && searchedElement.item.has_id_1c === true)).length !== 0 ?
                        <div className='client_content client_info cart'>
                            <div>
                                <div className="name">Товары под заказ</div>
                                {cart !== null &&
                                cart.some(
                                    searchedElement => searchedElement.amount > searchedElement.stock
                                ) && (
                                    <button
                                        className="clear_cart"
                                        data-type="all"
                                        onClick={this.deleteCartItem}
                                    >
                                        Очистить
                                    </button>
                                )}
                            </div>
                            <div className="cart-item">
                                {cart
                                    .map((el, idx) => (
                                        <>
                                            {el.item.has_id_1c === false || (el.amount > el.stock && el.item.has_id_1c === true) ?
                                                <div key={idx} className="cart-item container">
                                                    <div className="image-wrapper">
                                                        <img
                                                            src={
                                                                el.item.images.length > 0
                                                                    ? el.item.images[0]
                                                                    : nonAvatart
                                                            }
                                                            alt="Cart item image"
                                                        />
                                                    </div>
                                                    <div className="info-wrapper">
                                                        <div className="info header">
                                                            <Link
                                                                to={`/main/shop/product/${el.item.id}`}
                                                                className="heading"
                                                            >
                                                                {el.item.name}
                                                            </Link>
                                                            <img
                                                                className="delete"
                                                                src={cartCloseBtn}
                                                                alt="Delete cart item"
                                                                data-type="out"
                                                                data-id={el.item.id}
                                                                onClick={this.deleteCartItem}
                                                            />
                                                        </div>
                                                        <div className="info body">
                                                            <div className="input_add">
                                                                <img
                                                                    src={cartMinus}
                                                                    alt="cartminus"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        el.amount > 1 &&
                                                                        this.handlePurchaseValue(
                                                                            "-",
                                                                            el,
                                                                            false
                                                                        );
                                                                    }}
                                                                    style={el.amount <= 1 ? { opacity: "0.3" } : {}}
                                                                    className="cart-minus"
                                                                />
                                                                <input
                                                                    value={
                                                                        el.item.has_id_1c === false ?
                                                                            el.amount === 0 ? "" : el.amount  :
                                                                            el.amount - el.stock === 0 ? "" : el.amount - el.stock
                                                                    }
                                                                    onChange={e => {
                                                                        this.handleChangeInputValue(
                                                                            e.target.value,
                                                                            el,
                                                                            true
                                                                        );
                                                                    }}
                                                                    onClick={e => e.stopPropagation()}
                                                                    disabled
                                                                />
                                                                <img
                                                                    src={cartPlus}
                                                                    alt="cartplus"
                                                                    onClick={e => {
                                                                        e.stopPropagation();
                                                                        /* el.amount < 999 && */
                                                                        this.handlePurchaseValue("+", el, false);
                                                                    }}
                                                                    className="cart-plus"
                                                                    /*  style={
                                                                     el.amount >= 999 ? { opacity: "0.3" } : {}
                                                                 } */
                                                                />
                                                                <img
                                                                    src={Discount_edit}
                                                                    className="edit_btn"
                                                                    alt=""
                                                                    onClick={() => this.openQuantityModal(el)}
                                                                />
                                                            </div>
                                                            <div className="price">
                                                                {el.item.discount_price && (
                                                                    <span className="discount">
                                                                            {el.item.retail_price} грн
                                                                        </span>
                                                                )}
                                                                <span>
                                                                        {el.item.discount_price
                                                                            ? el.item.discount_price
                                                                            : el.item.retail_price}{" "}
                                                                    грн
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </>
                                    ))}
                            </div>
                        </div>
                        :null
                }
                <DialogComponent
                    open={quantityModal}
                    onClose={() => this.setState({ quantityModal: !quantityModal })}
                >
                    <form onSubmit={handleSubmit(() => this.setState({ quantityModal: !quantityModal }))}>
                        <div className="dialog_client quantity">
                            <div className={`modal_title`}>Изменение количества товара</div>
                            <div className="info body">
                                <div className="input_add">
                                    <input
                                        defaultValue={quantityModalTarget.amount}
                                        onChange={e => {
                                            this.handleChangeInputValue(
                                                Number(e.target.value),
                                                quantityModalTarget
                                            );
                                        }}
                                        onClick={e => e.stopPropagation()}
                                    />
                                </div>
                            </div>
                            {quantityModalTarget.item && quantityModalTarget.item.has_id_1c !== false ?
                                <>
                                    {quantityModalTarget.amount > quantityModalTarget.stock && (
                                        <div className="info footer">
                                            <img src={cartStockWarning} alt="Cart stock warning" />
                                            <span>
                                                Только {quantityModalTarget.stock} в наличии на складе.{" "}
                                                {quantityModalTarget.amount - quantityModalTarget.stock} - под заказ!
                                            </span>
                                        </div>
                                    )}
                                </>
                                : null
                            }
                            <div className="button_container">
                                <DefaultButton variant="contained" formAction>
                                    Сохранить
                                </DefaultButton>
                            </div>
                        </div>
                    </form>
                </DialogComponent>
            </div>
        );

    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, 5}$/i.test(values.email)) {
        errors.email = "Invalid email";
    }
    if (!values.password) {
        errors.password = "Required";
    } else if (values.password.length < 8) {
        errors.password = "Must be 8 characters or more";
    }
    return errors;
};

CartDialog = reduxForm({
    form: "CartDialogForm",
    validate
})(CartDialog);

const mapStateToProps = ({ client }) => {
    return {
        clientInfo: client.client_info,
        needDelivery: client.needDelivery,
        selectedDate: client.selectedDate,
        deliveryAddress: client.deliveryAddress
    };
};

const mapDispatchToProps = {
    GetClientInfo,
    sessionEnd,
    cartUpdate,
    setNeedDelivery,
    setAddress,
    setDate
};

export default connect(mapStateToProps, mapDispatchToProps)(CartDialog);
