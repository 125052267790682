import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import RenderField from "../HelperComponents/RenderField/RenderField";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import Frame_big from "../../assets/image/Frame_big.svg";
import "./ProductRequest.scss";

import { requestNewProd } from '../../actions/newProdAction';

class ProductRequest extends Component {
    state = {
        open: false
    };

    submitForm = data => {
        const { requestNewProd, reset } = this.props;
        if(data.price) data.price = +data.price
        if(data.phone_number) data.phone_number = `+380${data.phone_number}`
        requestNewProd(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                this.toggleDialog();
                reset();
            }
        });
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { open } = this.state;

        return (
            <TransitionedBlock classes="transitioned_block">

                <div className="product_request_page">
                    <div className="request_content">
                        <div className="title">Заявка на новый товар</div>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div>
                                <Field name="product_name" type="text" component={RenderField} label="Название товара*" />
                            </div>
                            <div>
                                <Field name="username" type="text" component={RenderField} label="Имя клиента" />
                            </div>
                            <div>
                                <Field name="phone_number" type="text" component={RenderField} label="Мобильный телефон" />
                            </div>
                            <div>
                                <Field name="price" type="number" component={RenderField} label="Цена товара" />
                            </div>
                            <div>
                                <Field name="description" type="text" component={RenderField} label="Комментарий" />
                            </div>
                            <button
                                className="good_hover"
                                disabled={submitting || pristine || !valid}
                            >Отправить заявку</button>
                        </form>
                    </div>
                    <DialogComponent
                        open={open}
                        onClose={this.toggleDialog}
                    >
                        <div className="dialog_request">
                            <img src={Frame_big} alt="Frame_big" />
                            <div>Заявка успешно <br /> отправлена</div>
                        </div>
                    </DialogComponent>
                </div>
            </TransitionedBlock>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.product_name) {
        errors.product_name = 'Обязательное поле';
    }
    if (!!values.phone_number) {
        /* if (values.phone_number[0] !== '+') {
            errors.phone_number = "Номер телефона должен начинаться с '+'";
        } else if (!values.phone_number.match(/(\+380){1,4}/g)) {
            errors.phone_number = "Номер должен начинаться с '+380'";
        } else if (!values.phone_number.match(/(\+380){1,4}\d{9,9}/g) || values.phone_number.length !== 13) {
            errors.phone_number = "В номере телефона после '+' должно следовать 12 цифр";
        } */
        if(values.phone_number.length !== 9) {
            errors.phone_number = "В номере телефона после кода страны должно быть 9 цифр";
        }
    }
    if (!!values.username) {
        if (values.username.replace(/\s/g, "").length === 0) {
            errors.username = 'Имя не может состоять только из пробелов';
        } else if (values.username.match(/[^a-zA-ZА-Яа-яЁё/\s]/g)) {
            errors.username = "Имя может состоять только из букв";
        }
    }
    return errors;
};

ProductRequest = reduxForm({
    form: "ProductRequestForm",
    validate
})(ProductRequest);

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = {
    requestNewProd
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductRequest);
