import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import RenderField from "../HelperComponents/RenderField/RenderField";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";

import "./Client.scss";

class ClientNotFound extends Component {


    submitForm = data => {
        const {history} = this.props;
        history.push('/main/client/registration');
    };


    render() {
        const { handleSubmit, submitting, pristine, valid, client_phone } = this.props;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="client_page">
                    <div className="client_content">
                        <div className="title">Клиент не найден</div>
                        <div className="descriptions">Код авторизации отправлен <br/>
                            на номер <strong>{client_phone}</strong> </div>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div>
                                <Field name="code" type="number" component={RenderField} label="Код авторизации"/>
                            </div>
                            <DefaultButton
                                variant="contained"
                                disabled={submitting || pristine || !valid}
                                formAction
                                /* type="link"
                                to="/main/client/registration" */
                            >
                                Регистрация клиента
                            </DefaultButton>
                            <div className="back_btn">

                                <DefaultButton
                                    variant="contained_grey"
                                    disabled={submitting || pristine || !valid}
                                    formAction
                                    type="link"
                                    to="/main/client/login"
                                >
                                    Отменить
                                </DefaultButton>
                            </div>
                        </form>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    if (!values.code) {
        errors.code = "Обязательное поле";
    } else if (values.code.length !== 6) {
        errors.code = "Должно быть 6 цифр";
    } else if (values.code !== props.code) {
        errors.code = "Неверный код авторизации";
    }
    return errors;
};

ClientNotFound = reduxForm({
    form: "ClientNotFoundForm",
    validate
})(ClientNotFound);

const mapStateToProps = ({ client }) => {
    return {
        code: client.code,
        client_phone: client.client_phone
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientNotFound);
