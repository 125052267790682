import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import RenderField from "../HelperComponents/RenderField/RenderField";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import { GetClientInfo } from '../../actions/clientAuthActions';
import "./Client.scss";

import CircularProgress from "@material-ui/core/CircularProgress";

import { phoneCheck } from "../../actions/clientAuthActions";

class AuthClient extends Component {
    state = {
        buttonLoad: localStorage.getItem('buttonLoad') || 'false',
    }

    submitForm = data => {
        const { phoneCheck, GetClientInfo, history } = this.props;
        let tempData = { ...data };
        tempData.phone_number = `+380${data.phone_number}`;
        localStorage.setItem('buttonLoad', 'true');
        this.setState({ buttonLoad: 'true' });
        phoneCheck(tempData).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                ///955359481
                if (res.payload.data.advertising_channel !== '') {
                    GetClientInfo(tempData.phone_number).then(res => {
                        if (res.payload && res.payload.status && res.payload.status === 200) {
                            localStorage.setItem('buttonLoad', 'false');
                            this.setState({ buttonLoad: 'false' });
                            localStorage.setItem("client_phone", tempData.phone_number);
                            localStorage.setItem('is_logged', 'true');
                            history.push("/main/client/info");
                        }
                    })
                } else {
                    localStorage.setItem('buttonLoad', 'false');
                    this.setState({ buttonLoad: 'false' });
                    history.push("/main/client/found");
                }
            } else if (res.error.response.status === 404) {
                localStorage.setItem('buttonLoad', 'false');
                this.setState({ buttonLoad: 'false' });
                history.push("/main/client/registration");
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { buttonLoad } = this.state;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="client_page">
                    <div className="client_content">
                        <div className="title">Авторизация клиента</div>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div>
                                <Field
                                    name="phone_number"
                                    type="text"
                                    component={RenderField}
                                    label="Мобильный телефон"
                                />
                            </div>
                            <DefaultButton
                                variant="contained"
                                disabled={submitting || pristine || !valid || buttonLoad === 'true'}
                                formAction
                                classes={`registration-progress`}
                            >
                                {buttonLoad === 'true' && <CircularProgress color="secondary" />}
                                Продолжить
                            </DefaultButton>
                        </form>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.phone_number) {
        errors.phone_number = "Обязательное поле";
    } /* else if (values.phone_number[0] !== '+') {
        errors.phone_number = "Номер телефона должен начинаться с '+'";
    }else if(!values.phone_number.match(/(\+380){1,4}/g)) {
        errors.phone_number = "Номер должен начинаться с '+380'";
    } else if (!values.phone_number.match(/(\+380){1,4}\d{9,9}/g) || values.phone_number.length !== 13) {
        errors.phone_number = "В номере телефона после '+' должно следовать 12 цифр";
    } */ else if (
        values.phone_number.match(/\D/g)
    ) {
        errors.phone_number = "В номере телефона должны быть только цифры";
    } else if (values.phone_number.length !== 9) {
        errors.phone_number = "В номере телефона после кода страны должно быть 9 цифр";
    }
    return errors;
};

AuthClient = reduxForm({
    form: "AuthClientForm",
    validate
})(AuthClient);

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    phoneCheck,
    GetClientInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthClient);
