import { CATEGORIES } from "../constants";
import { changeArray } from "../helpers/functions";

const INITIAL_STATE = {
    products: [],
    breadcrumbs: [],
    all_categories: [],
    products_results: [],
    error: false,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CATEGORIES.GET_CATEGORIES_SUCCESS:
            // console.log(action.payload.data)
            return { ...state, products: action.payload.data, products_results: action.payload.data.results/* .filter(el => el.retail_price !== 0) */, error: false };
        case CATEGORIES.GET_BREADCRUMBS_SUCCESS:
            // console.log(action.payload.data)
            return { ...state, breadcrumbs: action.payload.data, error: false };
        case CATEGORIES.GET_ALL_CATEGORIES_SUCCESS:
            return { ...state, all_categories: action.payload.data, error: false };
        case CATEGORIES.CATEGORIES_GET_STORAGES_SUCCESS:
            let newArr = changeArray(action.payload.config.productId, action.payload.data, state.products_results);
            return {
                ...state,
                storages: action.payload.data,
                products_results: newArr,
                error: false
            };
        case CATEGORIES.GET_ALL_CATEGORIES_FAIL:
            return { ...state, error: true };
        case CATEGORIES.GET_CATEGORIES_FAIL:
            return { ...state, error: true };
        case CATEGORIES.GET_BREADCRUMBS_FAIL:
            return { ...state, error: true };
        default:
            return state;
    }
}