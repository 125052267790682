import { CODE } from '../constants';

export function barcodeCheck(barcode) {
    return {
        type: CODE.CODE,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/products/barcode/?barcode=${barcode}`,
                method: "GET",
            }
        }
    };
}