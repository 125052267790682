import { SEARCH } from "../constants";
import { changeArray } from "../helpers/functions";

const INITIAL_STATE = {
    loading: true,
    search_all_result: {
        products: [],
        categories: []
    },
    search_result: {
        total_count: 0,
        products: [],
        categories: []
    }
};

export default function (state = INITIAL_STATE, action) {
    console.log(action)
    switch (action.type) {
        case SEARCH.SEARCH_ALL_SUCCESS:
            return { ...state, search_all_result: action.payload.data };
        case SEARCH.SEARCH:
            return { ...state, loading: true };
        case SEARCH.SEARCH_SUCCESS:
            return { ...state, search_result: action.payload.data, loading: false };
        case SEARCH.SEARCH_FAIL:
            state.loading = action.error.data === 'cancel';
            return { ...state };
        case SEARCH.SEARCH_GET_STORAGES_SUCCESS:
            if (action.payload.config.productsStoragesName === "search") {
                let newArr = changeArray(action.payload.config.productId, action.payload.data, state.search_all_result.products);
                return {
                    ...state,
                    search_result: {
                        total_count: state.search_result.total_count,
                        categories: state.search_result.categories,
                        products: newArr
                    },
                    search_all_result: {
                        categories: state.search_all_result.categories,
                        products: newArr
                    },
                    error: false
                };
            }
        default:
            return state;
    }
}