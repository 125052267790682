import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import "./DialogComponent.scss";
import PinchZoom from "pinch-zoom-js";
import CloseDialogIcon from "../../../assets/image/close.svg";
const DialogComponent = ({ open, onClose, children, className }) => {
    useEffect(() => {
        //console.log(document.querySelector('.dialog_product.lightbox img'), open)
        if (open) {
            setTimeout(() => {
                if (document.querySelector('.dialog_product.lightbox .img')) {
                    let el = document.querySelector('.dialog_product.lightbox .img');
                    let pz = new PinchZoom(el);
                }
            }, 0)

        }
    }, [open])
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className={className}
            classes={{
                root: `default_dialog_root`,
                paper: "paper_custom_dialog"
            }}
        >
            <div className="dialog_wrapper">
                <button onClick={onClose} className="dialog_close_button pulse">
                    <img src={CloseDialogIcon} alt="close icon" />
                </button>
                {children}
            </div>
        </Dialog>
    );
};

export default DialogComponent;
