import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import ShopPageCard from "./ShopPageCard";
import arrowDown from "../../assets/image/arrow-down.svg";

import "./ShopPage.scss";

const StockBlock = ({ loading, specialProducts, pageSize, getStorages, makeRequest, goToProd }) => {
    const bigScreen = useMediaQuery({ query: "(min-width: 1279px)" });
    const [amount, setAmount] = useState(bigScreen ? 12 : 8);
    useEffect(() => {
    }, [amount])
    return (
        <div className="mb40">
            <header className="content_header">
                <div className="title_page">Акции и скидки </div>
            </header>
            <div className="catalog_product">
                {loading ? (
                    <p>Идет загрузка товара...</p>
                ) : (
                    <>
                        {specialProducts && specialProducts.length > 0 ? (
                            specialProducts.slice(0, amount).map((el, idx) => {
                                let sum = 0,
                                    fullText = false;
                                el.storage_quantity &&
                                    el.storage_quantity.forEach(item => {
                                        sum += item.quantity ? item.quantity : 0;
                                    });
                                return <ShopPageCard
                                    el={el}
                                    idx={idx}
                                    sum={sum}
                                    goToProd={goToProd}
                                    getStorages={getStorages}
                                    productsStoragesName={'special_products'}
                                    makeRequest={makeRequest}
                                />
                            })
                        ) : (
                            <p>Нет товаров</p>
                        )}
                    </>
                )}
            </div>
            {!loading && specialProducts && specialProducts.length >= amount && (
                <div
                    className="more-products"
                    onClick={() => {
                        const plus = bigScreen ? 12 : 8;
                        setAmount(amount + plus);
                    }}
                >
                    <span>Смотреть еще</span>
                    <img src={arrowDown} />
                </div>
            )}
        </div>
    );
};

export default StockBlock;
