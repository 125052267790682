import { CATEGORIES, CLIENT } from "../constants";

export function phoneCheck(data) {
    return {
        type: CLIENT.PHONE_CHECK,
        payload: {
            client: "default",
            request: {
                url: `/tablet/customer/identification/`,
                method: "POST",
                data
            }
        }
    };
}

export function sessionEnd(phone_number, storage_id, discount_percent, products, products_on_order, address, date) {
    let data = {
        phone_number,
        storage_id,
        discount_percent,
        products,
        products_on_order,
        address,
        date
    };
    return {
        type: CLIENT.LOG_OUT,
        payload: {
            client: "default",
            request: {
                url: `/tablet/customer/session-end/`,
                method: "POST",
                data
            }
        }
    };
}

export function sendToPicking(storage_id) {
    let data = {
        storage_id: storage_id
    };
    return {
        type: CLIENT.SEND_TO_PICKING,
        payload: {
            client: "default",
            request: {
                url: `/tablet/send-to-picking/`,
                method: "POST",
                data
            }
        }
    };
}

export function getAdvChan(type) {
    return {
        type: CLIENT.GET_ADVERTISING_CHANNEL,
        payload: {
            client: "default",
            request: {
                url: `/tablet/customer/${type === "channel" ? "advertising-channel" : "segments"}/`,
                method: "GET"
            }
        }
    };
}

export function sendAdvChan(phone_number, advertising_channel, segment) {
    let data = {
        phone_number: phone_number,
        advertising_channel: advertising_channel,
        segment: segment
    };

    return {
        type: CLIENT.SEND_ADVERTISING_CHANNEL,
        payload: {
            client: "default",
            request: {
                url: `/tablet/customer/advertising-channel/`,
                method: "POST",
                data
            }
        }
    };
}

export function registration(data) {
    return {
        type: CLIENT.REGISTRATION,
        payload: {
            client: "default",
            request: {
                url: `/tablet/customer/register/`,
                method: "POST",
                data
            }
        }
    };
}

export function GetClientInfo(phone) {
    let data = {
        phone_number: phone
    };
    return {
        type: CLIENT.GET_CLIENT_INFO,
        payload: {
            client: "default",
            request: {
                url: `/tablet/customer/info/`,
                method: "POST",
                data
            }
        }
    };
}

export function cartUpdate() {
    return {
        type: CLIENT.CART_UPDATE
    };
}

export function setNeedDelivery(needDelivery) {
    return {
        type: CLIENT.SET_NEED_DELIVERY,
        payload: needDelivery
    };
}

export function setAddress(address) {
    return {
        type: CLIENT.SET_ADDRESS,
        payload: address
    };
}

export function setDate(date) {
    return {
        type: CLIENT.SET_DATE,
        payload: date
    };
}
