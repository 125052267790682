import { CATEGORIES } from '../constants';

export function getAllCategory() {
    return {
        type: CATEGORIES.GET_ALL_CATEGORIES,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/category/`,
                method: "GET",
            }
        }
    };
}

export function getCategoryProducts(url, secondUrl) {
    return {
        type: CATEGORIES.GET_CATEGORIES,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/category/${url}/products/${secondUrl}`,
                method: "GET",
            }
        }
    };
}

export function getBreadcrumbs(url) {
    return {
        type: CATEGORIES.GET_BREADCRUMBS,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/category/${url}/ancestors/`,
                method: "GET",
            }
        }
    };
}

