import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import nonAvatart from "../../assets/image/non-avatar.png";
import checkmark from "../../assets/image/checkmark.svg";
import pre_order from "../../assets/image/pre_order.svg";
import Discount from "../../assets/image/Discount.svg";
import Season from "../../assets/image/Season.svg";
import Stale from "../../assets/image/Stale.svg";
import DotsImg from "../../assets/image/dots-2.svg";
import CloseFullText from "../../assets/image/closeFullText.svg";
import WhitePlus from "../../assets/image/white-plus.svg";
import BlackMinus from "../../assets/image/black-minus.svg";
import "./ShopPage.scss";
import CustomSpinner from "../HelperComponents/CustomSpinner/CustomSpinner";
import cartPlus from "../../assets/image/plus.svg";
import cartMinus from "../../assets/image/minus.svg";
import cart from "../../assets/image/cart-img.svg";
import cartAdd from "../../assets/image/cart-add.svg";
import saleImg from "../../assets/image/sale-bg.svg";
import saleBadge from "../../assets/image/sale-badge.svg";
import topSalesBadge from "../../assets/image/top-sales.svg";

import { cartUpdate } from "../../actions/clientAuthActions";
import { storageFilter } from "../../helpers/functions";

const ShopPageCard = ({
    el,
    idx,
    sum,
    goToProd,
    getStorages,
    makeRequest,
    cartUpdate,
    productsStoragesName,
    visiblePrice
}) => {
    const [fullText, setFullText] = useState(false);
    const [openShops, setOpenShops] = useState(false);
    const [loading, setLoading] = useState(true);
    const [shopsArray, setShopsArray] = useState([]);
    const [amount, setAmount] = useState(1);
    const [addedToCart, setAddedToCart] = useState(false);
    const [shopsHeight, setShopsHeight] = useState(0);
    const [nameHeight, setNameHeight] = useState(40);
    const [error, setError] = useState(false);
    const [innerSpanHeight, setInnerHeight] = useState(0);

    const [clientInfo, setClientInfo] = useState(
        localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : false
    );

    const addToCart = inStock => {
        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            cartItem = {
                amount: Number(amount),
                stock: sum,
                item: el
            };
        // if item is allready exist and if this priceType is same to current
        if (
            cart.some(
                searchedElement =>
                    searchedElement.item.id === el.id &&
                    searchedElement.priceType === localStorage.getItem("default_price")
            )
        ) {
            cart[
                cart.findIndex(
                    searchedElement =>
                        searchedElement.item.id === el.id &&
                        searchedElement.priceType === localStorage.getItem("default_price")
                )
            ].amount += amount;
        } else {
            cart.push(cartItem);
        }
        localStorage.setItem("cart", JSON.stringify(cart));
        setAddedToCart(true);
        cartUpdate();
    };

    const handlePurchaseValue = sign => {
        if (sign === "+") {
            setAmount(amount + 1);
        } else {
            setAmount(amount - 1);
        }
    };

    const handleChangeInputValue = value => {
        let customValue;
        if (value == "" || value == " ") {
            customValue = "";
        } /* else if (+value > 999) {
            customValue = 999;
        } */ else {
            customValue = parseInt(value);
        }
        if (value >= 0) {
            setAmount(customValue);
        }
    };

    const makeRequestOwn = () => {
        /* makeRequest(el.id, !!clientInfo ? clientInfo.phone_number : false).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                addToCart('outStock');
            } else if (res.error.response.data.detail) {
            }
        }); */
        addToCart("outStock");
    };

    useEffect(() => {
        const ownHeigth =
            document.getElementById(`innername-${el.id}`) && document.getElementById(`innername-${el.id}`).offsetHeight;
        innerSpanHeight !== ownHeigth && setInnerHeight(ownHeigth);
    }, [document]);

    useEffect(() => {
        // console.log(nameHeight);
    }, [nameHeight]);

    const filteredStorages = storageFilter(shopsArray);

    console.log(filteredStorages);

    return (
        <div
            key={idx}
            onClick={() => goToProd(el.id)}
            className="product"
            style={{
                zIndex: 998 - idx,
                marginBottom: shopsHeight === 0 && nameHeight === 40 ? "14px" : `-${shopsHeight + (nameHeight - 40)}px`
            }}
        >
            <div className="art">Арт. {el.article_number}</div>
            <div className="icon" style={el.tablet_availability ? {} : { opacity: "0.3" }}>
                <img src={el.images[0] || nonAvatart} alt="product_catalog" />
            </div>
            <span className="name" id={`name-${el.id}`} style={{ maxHeight: !fullText ? "40px" : "100px" }}>
                <span id={`innername-${el.id}`}>{el.name}</span>
                {el.name && !fullText && innerSpanHeight > 35 && (
                    <img
                        src={DotsImg}
                        className="dots-wrapper show"
                        onClick={e => {
                            e.stopPropagation();
                            setTimeout(() => {
                                setFullText(true);
                                setNameHeight(
                                    document.getElementById(`name-${el.id}`) &&
                                        document.getElementById(`name-${el.id}`).offsetHeight
                                );
                            }, 1);
                        }}
                    />
                )}
                {el.name && fullText && (
                    <img
                        src={CloseFullText}
                        className="dots-wrapper hide"
                        onClick={e => {
                            e.stopPropagation();
                            setFullText(false);
                            setNameHeight(40);
                        }}
                    />
                )}
            </span>
            <div className="recent-products">
                <span className="recent-products-text">Остаток {sum} ед.</span>
                {!openShops && sum > 0 && (
                    <div
                        className="whiteplus-wrapper"
                        onClick={e => {
                            e.stopPropagation();
                            setOpenShops(true);
                            setShopsHeight(80);
                            getStorages(el.id, productsStoragesName).then(res => {
                                if (res.payload && res.payload.status && res.payload.status === 200) {
                                    console.log(res.payload.data);
                                    setShopsArray(res.payload.data);
                                    setLoading(false);
                                    setShopsHeight(
                                        document.getElementById(`shops-wrap-${el.id}`) &&
                                            document.getElementById(`shops-wrap-${el.id}`).offsetHeight
                                    );
                                } else {
                                    setLoading(false);
                                    setError(true);
                                }
                            });
                        }}
                    >
                        <img src={WhitePlus} alt="whiteplus" />
                    </div>
                )}
                {openShops && (
                    <div
                        className="minus-wrapper"
                        onClick={e => {
                            e.stopPropagation();
                            setOpenShops(false);
                            setLoading(true);
                            setShopsHeight(0);
                            setError(false);
                        }}
                    >
                        <img src={BlackMinus} alt="minus" />
                    </div>
                )}
            </div>
            {openShops && (
                <div className="shops-wrapper" id={`shops-wrap-${el.id}`}>
                    {loading ? (
                        <>
                            <CustomSpinner styles={{ marginTop: "13px", marginBottom: "9px" }} />
                            <p>Сбор информации</p>
                        </>
                    ) : error ? (
                        <p className="error-download">Ошибка</p>
                    ) : (
                        <div className="shops-wrapper-content">
                            {filteredStorages &&
                                filteredStorages.map(el => (
                                    <div className="shop-item" key={el.storage_id}>
                                        <p className="shop-item-name">{el.name}</p>
                                        <p>{el.quantity} ед.</p>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            )}
            {/* {el.tablet_availability ? (
                <div className="in_stock">
                    <img src={checkmark} alt="checkmark" />
                    <span>В наличии</span>
                </div>
            ) : (
                <div className="pre_order">
                    <img src={pre_order} alt="pre_order" />
                    <span>Под заказ</span>
                </div>
            )} */}
            <div className="price-wrapper" style={el.tablet_availability ? {} : { opacity: "0.3" }}>
                {!!el.discount_price && <div className="price_product discount">{el[visiblePrice]} грн</div>}
                {el.has_id_1c === false ? (
                    <div className="price_product">{el.discount_price || el.price} грн</div>
                ) : (
                    <div className="price_product">{el.discount_price || el[visiblePrice]} грн</div>
                )}
            </div>
            {el.tablet_availability ? (
                <div className="cart-wrapper">
                    <div className="input_add">
                        <img
                            src={cartMinus}
                            alt="cartminus"
                            onClick={e => {
                                e.stopPropagation();
                                amount > 1 && handlePurchaseValue("-");
                            }}
                            style={amount <= 1 ? { opacity: "0.3" } : {}}
                            className="cart-minus"
                        />
                        <input
                            value={amount === 0 ? "" : amount}
                            onChange={e => {
                                handleChangeInputValue(e.target.value, idx);
                            }}
                            onClick={e => e.stopPropagation()}
                        />
                        <img
                            src={cartPlus}
                            alt="cartplus"
                            onClick={e => {
                                e.stopPropagation();
                                /* amount < 999 && */ handlePurchaseValue("+");
                            }}
                            className="cart-plus"
                            style={amount >= 999 ? { opacity: "0.3" } : {}}
                        />
                    </div>
                    {addedToCart ? (
                        <div className="cart-added-wrapper">
                            <img src={cartAdd} alt="cartadded" />
                        </div>
                    ) : (
                        <div
                            className={`cart-add-wrapper${amount === "" || amount === 0 ? " disabled" : ""}`}
                            onClick={e => {
                                e.stopPropagation();
                                amount !== "" && amount !== 0 && addToCart("inStock");
                            }}
                        >
                            <img src={cart} alt="cartadd" />
                        </div>
                    )}
                </div>
            ) : (
                <div className="cart-wrapper">
                    {/* <p className="no-items">Нет в наличии</p> */}
                    <div className="input_add">
                        <img
                            src={cartMinus}
                            alt="cartminus"
                            onClick={e => {
                                e.stopPropagation();
                                amount > 1 && handlePurchaseValue("-");
                            }}
                            style={amount <= 1 ? { opacity: "0.3" } : {}}
                            className="cart-minus"
                        />
                        <input
                            value={amount === 0 ? "" : amount}
                            onChange={e => {
                                handleChangeInputValue(e.target.value, idx);
                            }}
                            onClick={e => e.stopPropagation()}
                        />
                        <img
                            src={cartPlus}
                            alt="cartplus"
                            onClick={e => {
                                e.stopPropagation();
                                /* amount < 999 && */ handlePurchaseValue("+");
                            }}
                            className="cart-plus"
                            style={amount >= 999 ? { opacity: "0.3" } : {}}
                        />
                    </div>
                    {addedToCart ? (
                        <div className="cart-added-wrapper">
                            <img src={cartAdd} alt="cartadded" />
                        </div>
                    ) : (
                        <div
                            className={`cart-add-wrapper cart-no-items-add${
                                amount === "" || amount === 0 ? " disabled" : ""
                            }`}
                            onClick={e => {
                                e.stopPropagation();
                                amount !== "" && amount !== 0 && makeRequestOwn();
                            }}
                        >
                            <img src={cart} alt="cartadd" />
                        </div>
                    )}
                </div>
            )}

            <div className="info_product_">
                {/* {el.promotional ? (
                    <div className="card-discount">
                        <img src={saleImg} alt="Discount" />
                        <span>-{100 - Math.round((el.discount_price * 100) / el.retail_price)}%</span>
                    </div>
                ) : el.seasonal ? (
                    <img src={Season} alt="Season" />
                ) : el.old ? (
                    <img src={Stale} alt="Stale" />
                ) : null} */}
                {el.sale ? (
                    <img src={saleBadge} alt="sale" />
                ) : el.top_sales ? (
                    <img src={topSalesBadge} alt="topSales" />
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = store => {
    return {};
};

const mapDispatchToProps = {
    cartUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCard);
