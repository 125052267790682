import React, { Component } from "react";
import { connect } from "react-redux";
import "./CategorySidebar.scss";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import CatArrow from "../../assets/image/cat-arrow.svg";
import CatArrow2 from "../../assets/image/cat-arrow-2.svg";
import { withRouter } from "react-router-dom";
import logo from "../../assets/image/new_logo.svg";
import close from "../../assets/image/close-sidebar.svg";
import Advantages from "../../assets/image/advantages-icon.svg";
import { ClickAwayListener } from "@material-ui/core";

import { getAllCategory, getBreadcrumbs } from "../../actions/categoriesActions";

class CategorySidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curCat: { cats: [], tree: [] },
            loading: true,
            labels: []
        };
        this.requiredNodes = [
            {
                id: 'advantages',
                icon: Advantages,
                name: 'Преимущества',
            }
        ]
    }

    componentDidMount() {
        //this.breads();
        this.getCategories();
        this.askNewCat();
        // this.resetBugs();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            //this.breads();
            this.askNewCat();
            this.resetBugs();
        }
        if (prevState.curCat !== this.state.curCat) {
            this.setState({ loading: false });
            this.resetBugs();
        }
        this.resetBugs();
    }

    breads = () => {
        const { getBreadcrumbs } = this.props;
        getBreadcrumbs(this.props.location.search.split("?cat=")[1]);
    };

    resetBugs = () => {
        let expandedLabels = document.getElementsByClassName("Mui-expanded");

        for (let i = 0; i < expandedLabels.length; i++) {
            if (expandedLabels[i].querySelector(".MuiTreeItem-iconContainer").innerHTML === "") {
                expandedLabels[i].classList.remove("Mui-expanded");
            }
        }
    };

    getLabelsArray = target => {
        if (target.closest(".cat_item").parentElement.closest(".cat_item")) {
            this.setState(({ labels }) => ({
                labels: [...labels, target.closest(".cat_item").parentElement.closest(".cat_item").firstChild.lastChild]
            }));
            this.getLabelsArray(target.closest(".cat_item").parentElement.closest(".cat_item").firstChild.lastChild);
        } else {
            setTimeout(this.applyLabelsStyle, 0);
        }
    };

    applyLabelsStyle = () => {
        let { labels } = this.state;
        console.log(labels);
        labels.reverse().map((el, idx) => {
            return (el.style.marginLeft = `${20 * (idx + 1)}px`);
        });
    };

    getCategories = () => {
        const { getAllCategory } = this.props;

        getAllCategory().then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
    };

    askNewCat = () => {
        let newTree = [];
        let newCat = [];
        let {
            certain_product,
            getBreadcrumbs,
            location: { search, pathname }
        } = this.props;
        if (search.includes("?cat="))
            return getBreadcrumbs(search.split("?cat=")[1]).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    if (search.includes("?cat=")) {
                        if (res.payload.data && res.payload.data.length > 0) {
                            res.payload.data.forEach((el, idx) => {
                                el !== "" && newTree.push(`${el.id}`);
                                el !== "" && idx !== res.payload.data.length - 1 && newCat.push(`${el.id}`);
                            });
                        }
                        // console.log({ cats: ["root", ...newCat], tree: ["root", ...newTree] });
                        this.setState({ curCat: { cats: ["root", ...newCat], tree: ["root", ...newTree] } });
                    } /*  else if (pathname.includes('/product/')) {
                    if (certain_product && certain_product.category_tree && certain_product.category_tree.length > 0) {
                        certain_product.category_tree.forEach(el => {
                            el !== '' && newTree.push(`${el.id}`);
                        })
                    }
                    this.setState({ curCat: { cats: res.payload.data, tree: ['root', ...newTree] } });
                } */ else {
                        this.setState({ curCat: { cats: [], tree: ["root"] } });
                    }
                }
            });
    };

    renderTree = nodes => {
        const { history } = this.props;
        if (Array.isArray(nodes)) {
            return (<>
                {this.requiredNodes.map(node => this.renderTree(node))}
                {nodes.map(node => this.renderTree(node))}
            </>);
        } else {
            return (
                <TreeItem
                    className={`cat_item ${typeof nodes.id === 'string'
                        ? history.location.pathname.includes(nodes.id)
                            ? "current_cat required-nodes"
                            : "required-nodes"
                        : this.props.location.search.includes("?cat=")
                            ? history.location.search === `?cat=${nodes.id}`
                                ? "current_cat"
                                : ""
                            : ""
                        }`}
                    key={nodes.id}
                    nodeId={`${nodes.id}`}
                    label={
                        <div className="tree-item-content">
                            {nodes.icon && <img src={nodes.icon} />}
                            {nodes.name}
                        </div>
                    }
                    onLabelClick={e => {
                        e.preventDefault();
                        document.querySelector(".shop_content") &&
                            document.querySelector(".shop_content").scrollTo({
                                top: 0,
                                behavior: "smooth"
                            });
                        setTimeout(() => {
                            history.push(nodes.id === "root" ? `/main/shop` : typeof nodes.id === 'string' ? `/main/info/${nodes.id}` : `/main/shop?cat=${nodes.id}`);
                            this.setState(({ curCat }) => ({
                                labels: [],
                                curCat: { cats: curCat.cats, tree: [...curCat.tree, `${nodes.id}`] }
                            }));
                        }, 0);
                        //this.getLabelsArray(e.target);
                    }}
                >
                    {nodes.children && nodes.children.length > 0
                        ? nodes.children.map(node => this.renderTree(node))
                        : null}
                </TreeItem>
            );
        }
    };

    expand = (e, id) => {
        this.setState(({ curCat }) => ({ curCat: { ...curCat, tree: id } }));
    };

    select = (e, id) => {
        console.log(id);
    };

    render() {
        const { all_categories, closeEvent, history, catalogOpen } = this.props;
        const { curCat, loading } = this.state;
        if (all_categories.length < 1 || loading) return <aside className="sidebar_wrapper" />;
        return (
            <ClickAwayListener onClickAway={catalogOpen ? closeEvent : () => { }}>
                <aside className="sidebar_wrapper">
                    <div className="logo-wrapper">
                        <img
                            src={logo}
                            alt="logo"
                            onClick={() => {
                                history.push(`/main/shop`);
                                closeEvent();
                            }}
                        />
                        <img src={close} alt="close" onClick={closeEvent} />
                    </div>
                    <TreeView
                        className="cat_sidebar"
                        defaultExpandIcon={<img src={CatArrow} alt="cat arrow" />}
                        defaultCollapseIcon={<img src={CatArrow2} alt="cat arrow" />}
                        selected={`${curCat.tree.length > 0 ? curCat.tree[curCat.tree.length - 1] : "root"}`}
                        //defaultExpanded={curCat.tree}
                        expanded={curCat.tree}
                        //selected={curCat.tree}
                        //expanded={curCat.tree}
                        //multiSelect
                        onNodeToggle={this.expand}
                        onNodeSelect={this.select}
                    >
                        {this.renderTree(all_categories)}
                    </TreeView>
                </aside>
            </ClickAwayListener>
        );
    }
}

const mapStateToProps = state => {
    return {
        all_categories: state.category.all_categories,
        breadcrumbs: state.category.breadcrumbs,
        certain_product: state.products.certain_product
    };
};
const mapDispatchToProps = {
    getAllCategory,
    getBreadcrumbs
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategorySidebar));
