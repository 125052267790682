import React, { Component } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";

import "./Specifications.scss";

class Specifications extends Component {


    render() {
        const { desc, chars } = this.props;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="specifications_wrapper">
                    <div className="specifications_block">
                        <div className="specifications">
                            <div className="title_block">Характеристики</div>
                            <div className="info_wrapper">
                                {chars.map((el, idx) => (
                                    <div className="block" key={idx}>
                                        <span>{el.name}</span>
                                        <p>{el.formatted_values}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="info_product">
                            <div className="title_block">О товаре</div>
                            <div className="info" dangerouslySetInnerHTML={{__html: desc}} />
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Specifications);
