import { PRODUCTS } from "../constants";
import { changeArray } from "../helpers/functions";

const INITIAL_STATE = {
    popular_products: [],
    certain_product: {},
    prod_characteristics: {},
    related_products: [],
    storages: [],
    error: false,
    special_products: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case PRODUCTS.GET_POPULAR_PRODUCTS_SUCCESS:
            return { ...state, popular_products: action.payload.data, error: false };
        case PRODUCTS.GET_POPULAR_PRODUCTS_FAIL:
            return { ...state, error: true };
        case PRODUCTS.GET_SPECIAL_PRODUCTS_SUCCESS:
            return { ...state, special_products: action.payload.data, error: false };
        case PRODUCTS.GET_RELATED_PRODUCTS_SUCCESS:
            return { ...state, related_products: action.payload.data, error: false };
        case PRODUCTS.PRODUCTS_GET_STORAGES_SUCCESS:
            if (
                action.payload.config.productsStoragesName === "special_products" ||
                action.payload.config.productsStoragesName === "popular_products"
            ) {
                let newArr = changeArray(
                    action.payload.config.productId,
                    action.payload.data,
                    state[action.payload.config.productsStoragesName]
                );
                return {
                    ...state,
                    storages: action.payload.data,
                    [action.payload.config.productsStoragesName]: newArr,
                    error: false
                };
            } else {
                return {
                    ...state,
                    storages: action.payload.data,
                    error: false
                };
            }
        case PRODUCTS.GET_CERTAIN_PRODUCT_FAIL:
            return { ...state, error: true };
        case PRODUCTS.GET_CERTAIN_PRODUCT_SUCCESS:
            let characteristics = [];
            action.payload.data.feature.forEach(el => {
                let char = el,
                    tempArr = [];
                char.values.forEach(elem => {
                    tempArr.push(elem.value);
                });
                char.formatted_values = tempArr.join(", ");
                characteristics.push(char);
            });
            return {
                ...state,
                certain_product: action.payload.data,
                prod_characteristics: characteristics,
                error: false
            };
        default:
            return state;
    }
}
