import React, { Component } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import AllInfo from "../ProductInner/AllInfo/AllInfo";
import BuyTogether from "../ProductInner/BuyTogether/BuyTogether";
import Specifications from "../ProductInner/Specifications/Specifications";
import Rating from "../HelperComponents/Rating/Rating";
import Discount from "../../assets/image/Discount.svg";
import Season from "../../assets/image/Season.svg";
import Stale from "../../assets/image/Stale.svg";
import { Link } from "react-router-dom";
import chevron from "../../assets/image/chevron-right.svg";
import "./ProductInner.scss";

import { getCertainProduct, getRelatedProducts, getStorages } from '../../actions/productsActions';

class ProductInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "AllInfo",
            url: props.match.params.slug,
            loading: true,
            storageFail: false
        };
    };

    componentDidMount() {
        const { url } = this.state;
        this.getProduct(url);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.setState({ url: this.props.match.params.slug })
        }
        if (prevState.url !== this.state.url) {
            this.getProduct(this.state.url);
        }
    }

    getProduct = slug => {
        const { getCertainProduct, getStorages } = this.props;

        getCertainProduct(slug).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ tab: "AllInfo", loading: false });
                getStorages(res.payload.data.id).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        this.setState({ storageFail: false })
                    } else if (res.error.response.data.detail) {
                        this.setState({ storageFail: true, storageFailText: res.error.response.data.detail })
                    }
                });
            }
        });
    }

    changeTab = tab => {
        this.setState({ tab });
    };

    BuyTogether = () => {
        const { getRelatedProducts } = this.props;
        const { url } = this.state;

        getRelatedProducts(url).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.changeTab("BuyTogether");
            }
        });
    }

    goToChars = () => {
        //tab_block
        //querySelector('.tab_block').getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({
            top: document.querySelector('div.name').getBoundingClientRect().top + window.pageYOffset - 15,
            behavior: "smooth"
        })
        this.changeTab("Specifications");

    }

    changeErrorText = value => {
        this.setState({ storageFail: true, storageFailText: value })
    }

    render() {
        const { tab, loading, storageFail, storageFailText } = this.state;
        const { currentProd, storages, chars, relatedProducts } = this.props;
        if (loading) return null;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="wrapper_page_inner">
                    <div className="title_block_inner">
                        <div className="breadcrumbs_product">
                            <Link to="/main/shop">
                                Каталог
                               <img src={chevron} alt="chevron" />
                            </Link>
                            {currentProd.category_tree.map((el, idx) => (
                                <Link key={idx} to={`/main/shop?cat=${el.id}`}>
                                    {el.name}
                                    <img src={chevron} alt="chevron" />
                                </Link>
                            ))}
                            <span>{currentProd.name}</span>

                        </div>
                        <div className="name">
                            {currentProd.discount
                                ? <img src={Discount} alt="Discount" />
                                : currentProd.seasonal
                                    ? <img src={Season} alt="Season" />
                                    : currentProd.old
                                        ? <img src={Stale} alt="Stale" />
                                        : null
                            }
                            {currentProd.name}
                        </div>
                        <div className="info_product_inner">
                            <Rating value={currentProd.rating} />
                            <div className="art">Артикул: {currentProd.article_number}</div>
                        </div>
                    </div>

                    <div className="tab_block">
                        <button
                            className={tab === "AllInfo" ? "active pulse" : "pulse"}
                            onClick={() => this.changeTab("AllInfo")}
                        >
                            Все о товаре
                        </button>
                        <button
                            className={tab === "BuyTogether" ? "active pulse" : "pulse"}
                            onClick={this.BuyTogether}
                        >
                            Вместе покупают
                        </button>
                        <button
                            className={tab === "Specifications" ? "active pulse" : "pulse"}
                            onClick={() => this.changeTab("Specifications")}
                        >
                            Характеристики
                        </button>
                    </div>

                    {tab === "AllInfo" && (
                        <TransitionedBlock>
                            <AllInfo
                                currentProd={currentProd}
                                chars={chars}
                                storages={storages}
                                isStorError={storageFail}
                                storageFailText={storageFailText}
                                changeErrorText={(value) => this.changeErrorText(value)}
                                showAllChars={this.goToChars}
                            />
                        </TransitionedBlock>
                    )}

                    {tab === "BuyTogether" && (
                        <TransitionedBlock>
                            <BuyTogether
                                relatedProducts={relatedProducts}
                                push={this.props.history.push}
                            />
                        </TransitionedBlock>
                    )}

                    {tab === "Specifications" && (
                        <TransitionedBlock>
                            <Specifications
                                chars={chars}
                                desc={currentProd.description}
                            />
                        </TransitionedBlock>
                    )}
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ products }) => {
    return {
        currentProd: products.certain_product,
        chars: products.prod_characteristics,
        relatedProducts: products.related_products,
        storages: products.storages,
    };
};

const mapDispatchToProps = {
    getCertainProduct,
    getRelatedProducts,
    getStorages
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductInner);
