import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../HelperComponents/DefaultButton/DefaultButton";

import logo_auth from "../../../../assets/image/logo_auth.svg";
import arrow_back from "../../../../assets/image/arrow_back.svg";

import {sendMail} from '../../../../actions/authActions';

class FirstStep extends Component {
    submitForm = data => {
        const {history, sendMail} = this.props;
        return sendMail(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.setItem('recovery_mail', data.email);
                history.push('/auth/reset-password/second-step');
            } else if(res.error.response.data.detail) {
                throw new SubmissionError({ email: res.error.response.data.detail });
            } else if (res.error) {
                throw new SubmissionError({ ...res.error.response.data });
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;

        return (
            <TransitionedBlock>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="back_block">
                        <Link className="good_hover" to={`/auth/login`}>
                            <img src={arrow_back} alt="arrow_back" />
                            Вернуться к авторизации
                        </Link>
                    </div>
                    <div className="logo_block_auth">
                        <img src={logo_auth} alt="logo_auth" />
                    </div>
                    <div className="title_auth">Восстановление пароля</div>
                    <div>
                        <Field name="email" type="text" component={RenderField} label="Электронная почта"/>
                    </div>
                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            disabled={submitting || pristine || !valid}
                            formAction
                        >
                            Продолжить
                        </DefaultButton>
                    </div>
                </form>
            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)) {
        errors.email = "Неправильный email";
    }
    return errors;
};

FirstStep = reduxForm({
    form: "FirstStep",
    validate
})(FirstStep);

const mapDispatchToProps = {
    sendMail
};

export default connect(null, mapDispatchToProps)(FirstStep);
