import React, {Component} from "react";
import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import DefaultButton from "../../../HelperComponents/DefaultButton/DefaultButton";

import {connect} from "react-redux";

import arrow_back from "../../../../assets/image/arrow_back.svg";
import logo_auth from "../../../../assets/image/logo_auth.svg";
import Frame from "../../../../assets/image/Frame.svg";

class FourthStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            security_token: localStorage.getItem('temp_token'),
        };
    }

    back = () => {
        const {history} = this.props;
        localStorage.clear();
        history.push('/auth/login');
    }

    login = () => {
        const {history} = this.props;
        const {security_token} = this.state;
        //localStorage.setItem('token', security_token);
        localStorage.removeItem('temp_token');
        history.push('/auth/login');
    }

    render() {
        return (
            <TransitionedBlock>
                <form>
                    <div className="back_block">
                        <a className="good_hover" href onClick={this.back}>
                            <img src={arrow_back} alt="arrow_back" />
                            Вернуться к авторизации
                        </a>
                    </div>
                    <div className="logo_block_auth">
                        <img src={logo_auth} alt="logo_auth" />
                    </div>

                    <div className="res_pas_ok">
                        <img src={Frame} alt="Frame" />
                    </div>

                    <div className="title_auth">Пароль успешно изменен</div>

                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            formAction
                            onClick={this.login}
                        >
                            Войти
                        </DefaultButton>
                    </div>
                </form>

            </TransitionedBlock>
        );
    }
}



function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = {

}
   
export default connect(mapStateToProps, mapDispatchToProps)(FourthStep);


