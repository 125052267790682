import React from "react";
import Select, { components } from "react-select";

import expand_icon from "../../../assets/image/arrow_select.svg";
import "./SelectComponent.scss";

export const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <div
                    className={props.selectProps.menuIsOpen ? "select_indicator indicator_active" : "select_indicator"}
                >
                    <img src={expand_icon} alt="expand_icon" />
                </div>
            </components.DropdownIndicator>
        )
    );
};

const SelectComponent = ({
    menuIsOpen,
    value,
    onKeyDown,
    options,
    loading,
    loadingMessage = "Loading filters...",
    change,
    placeholder,
    isClearable,
    isSearchable = true,
    disabled = false,
    menuPortalTarget,
    ...props
}) => {
    return (
        <Select
            menuIsOpen={menuIsOpen}
            className="select_component"
            classNamePrefix="select"
            isDisabled={disabled}
            isLoading={loading}
            isClearable={!isClearable}
            isSearchable={isSearchable}
            name="color"
            value={value}
            options={options}
            onChange={change}
            loadingMessage={() => loadingMessage}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            components={{ DropdownIndicator }}
            menuPortalTarget={menuPortalTarget && document.body}
            styles={menuPortalTarget && { menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            {...props}
        />
    );
};

export default SelectComponent;
