import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import RenderField from "../HelperComponents/RenderField/RenderField";
import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";

import { sendAdvChan, getAdvChan, GetClientInfo } from '../../actions/clientAuthActions';

import { getOption } from "../../helpers/functions";
import "./Client.scss";

class ClientFound extends Component {
    state = {
        channel: null,
        segment: null,
        channels: [],
        segments: []
    };

    componentDidMount() {
        this.getInfo('channel');
        this.getInfo('segment');
    }

    getInfo = type => {
        const { getAdvChan } = this.props;
        getAdvChan(type).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    [`${type}s`]: res.payload.data.map((el, idx) => ({ label: getOption(el), value: el, id: idx }))
                })
            }
        })
    };

    submitForm = data => {
        const { history, client_phone, sendAdvChan, GetClientInfo } = this.props;
        const { channel, segment } = this.state;
        sendAdvChan(client_phone, channel.value, segment.value).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                GetClientInfo(client_phone).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        localStorage.setItem('client_phone', client_phone);
                        localStorage.setItem('is_logged', 'true');
                        history.push('/main/client/info');
                    }
                })
            }
        })
    };

    handleChange = name => event => {
        this.setState({ [name]: event });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid, client_phone } = this.props;
        const { channel, channels, segment, segments } = this.state;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="client_page">
                    <div className="client_content">
                        <div className="title">Клиент найден</div>
                        <div className="descriptions">Откуда узнали о нас?</div>
                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div className={`advertising_channel`}>
                                <Field
                                    value={channel}
                                    options={channels}
                                    component={SelectComponent}
                                    change={this.handleChange("channel")}
                                    isClearable="false"
                                    isSearchable={false}
                                    placeholder="Откуда узнали о нас"
                                />
                            </div>
                            <div className={`advertising_channel`}>
                                <Field
                                    value={segment}
                                    options={segments}
                                    component={SelectComponent}
                                    change={this.handleChange("segment")}
                                    isClearable="false"
                                    isSearchable={false}
                                    placeholder="Сегмент Клиента"
                                />
                            </div>
                            <DefaultButton
                                variant="contained"
                                disabled={channel === null || segment === null}
                                formAction
                            /* type="link"
                            to="/main/client/info" */
                            >
                                Продолжить
                            </DefaultButton>
                            <div className="back_btn">

                                <DefaultButton
                                    variant="contained_grey"
                                    disabled={submitting || pristine || !valid}
                                    formAction
                                    type="link"
                                    to="/main/client/login"
                                >
                                    Отменить
                                </DefaultButton>
                            </div>
                        </form>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const validate = (values, props) => {
    const errors = {};
    /* if (!values.code) {
        errors.code = "Обязательное поле";
    } else if (values.code.length !== 4) {
        errors.code = "Должно быть 4 цифры";
    } else if (values.code !== props.code) {
        errors.code = "Неверный код авторизации";
    } */
    return errors;
};

ClientFound = reduxForm({
    form: "ClientFoundForm",
    validate
})(ClientFound);

const mapStateToProps = ({ client }) => {
    return {
        client_phone: client.client_phone
    };
};

const mapDispatchToProps = {
    sendAdvChan,
    getAdvChan,
    GetClientInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientFound);
