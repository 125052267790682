import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useDispatch } from 'react-redux';
import { barcodeCheck } from '../../actions/codeActions';

import './Camera.scss';

const Camera = ({ history }) => {
    const dispatch = useDispatch();
    const [code, setCode] = useState(undefined);
    const [isError, setError] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (navigator.getUserMedia) {
            navigator.getUserMedia(
                {
                    video: true
                },
                function (localMediaStream) { },
                function (err) {
                    console.log('The following error occurred when trying to access the camera: ' + err);
                }
            );
        } else {
            console.log('Sorry, browser does not support camera access');
        }
    }, [])

    useEffect(() => {
        if (code !== undefined) {
            dispatch(barcodeCheck(code)).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    setError(false);
                    history.push(`/main/shop/product/${res.payload.data.id}`);
                } else {
                    setError(true);
                    setNotFound(code);
                    setTimeout(() => setNotFound(false), 5000);
                }
            })
        }
    }, [code])

    const renderError = () => {
        if (isError) {
            if (!!notFound) {
                return <>Товар с баркодом <b>{notFound}</b> не найден в базе</>
            } else if (code === undefined) {
                return 'Баркод не распознан';
            }
        } else {
            return
        }
    };

    return (
        <div className="camera-page">
            <BarcodeScannerComponent
                height={(window.innerHeight - 125) / 100 * 60}
                onUpdate={(err, result) => {
                    if (result) setCode(result.text);
                    else setCode(undefined);
                }}
            />
            <p className='camera-error'>{renderError()}</p>
        </div>
    )
}

export default Camera;