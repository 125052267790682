import { SEARCH } from '../constants';

export function getAllResults(search, query = '') {
    return {
        type: SEARCH.SEARCH_ALL,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/products/search/${search}/all/${query}`,
                method: "GET",
            }
        }
    };
}

export function getSearchResults(search, cancelToken) {
    return {
        type: SEARCH.SEARCH,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/products/search/${search}/`,
                method: "GET",
                cancelToken: cancelToken.token
            }
        }
    };
}