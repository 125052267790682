import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import { getBreadcrumbs, getCategoryProducts } from "../../actions/categoriesActions";
import { getPopularProducts, getSpecialProducts, getStorages, makeRequest } from "../../actions/productsActions";
import StockBlock from "./StockBlock";
import ShopPageCard from "./ShopPageCard";
import FilterComponent from "../HelperComponents/FilterComponent";
import SortComponent from "../HelperComponents/SortComponent";
import "./ShopPage.scss";

class ShopPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 1,
            url: props.location.search.split("?cat=")[1],
            pageSize: 24,
            amount: 8,
            title: "",
            filter: [],
            sort: "",
        };
    }

    componentDidMount() {
        const { url } = this.state;
        if (url) {
            document.querySelector(".shop_content").addEventListener("scroll", this.scrollEvent);
        } else {
            this.getPop();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.search !== prevProps.location.search) {
            const { location: { search } } = this.props;
            let newUrl = search.split("?cat=")[1];

            this.setState({ activePage: 1, url: newUrl, pageSize: 24, loading: true });

            if (newUrl) {
                this.getCat(newUrl, 1);
                this.breads();
            } else {
                this.getPop();
            }
        }
        if (prevProps.products.category !== this.props.products.category) {
            this.setState({ loading: false });
        }
        if (prevProps.breadcrumbs !== this.props.breadcrumbs) {
            this.props.breadcrumbs[this.props.breadcrumbs.length - 1] && this.props.breadcrumbs[this.props.breadcrumbs.length - 1].name && this.setState({ title: this.props.breadcrumbs[this.props.breadcrumbs.length - 1].name });
        }

        // Filter & sorting
        const { filter, sort } = this.state;
        if (prevState.filter !== filter || prevState.sort !== sort) {
            const { location: { search } } = this.props;
            let newUrl = search.split("?cat=")[1];

            let filterQuery = "";
            let sortQuery = "";
            if (filter.length > 0) filterQuery = `&filter=${filter.join(",")}`;
            if (sort.length > 0) sortQuery = `&ordering=${sort}`;

            if (filter.length > 0 || sort.length > 0) {
                this.setLoading();
                this.getCat(newUrl, 1, `${filterQuery}${sortQuery}`);
            } else {
                this.setLoading();
                this.getCat(newUrl, 1);
            }
        }
    }

    scrollEvent = () => {
        const { url } = this.state;
        let target = document.querySelector(".shop_content");
        if (target.scrollTop === target.scrollHeight - target.getBoundingClientRect().height) {
            this.setState(({ pageSize }) => ({ pageSize: pageSize + 24 }));
        }
    };

    getPop = () => {
        Promise.all([this.props.getPopularProducts(), this.props.getSpecialProducts()]).then(res => {
            if (res.every(el => el.payload && el.payload.status && el.payload.status === 200)) {
                this.setState({ loading: false });
            }
        });
    };

    getCat = (url, page, query) => {
        const { getCategoryProducts } = this.props;
        let secondUrl = "";

        let filterSortQuery = "";
        if (query && query.length > 0) filterSortQuery = query;

        if (page) secondUrl += `?page=${page}&page_size=${20000}${filterSortQuery}`;

        getCategoryProducts(url, secondUrl).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({ loading: false });
            }
        });
    };

    breads = () => {
        const { getBreadcrumbs } = this.props;
        getBreadcrumbs(this.props.location.search.split("?cat=")[1]);
    };

    changePage = page => {
        let { url } = this.state;

        this.setState({ activePage: page.selected + 1 });
        this.getCat(url, page.selected + 1);
    };

    goToProd = slug => {
        const { history } = this.props;

        history.push(`/main/shop/product/${slug}`);
    };

    // Filter & sorting
    setLoading = () => this.setState({ loading: true });
    handleFilter = (values) => this.setState({ filter: values });
    handleSort = (value) => this.setState({ sort: value });

    render() {
        let { loading, url, pageSize, title } = this.state;
        let { products, getStorages, makeRequest, specialProducts, breadcrumbs } = this.props;

        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="page_wrapper shop_page">
                    <div className="shop_content">
                        {!url && (
                            <StockBlock
                                loading={loading}
                                specialProducts={specialProducts}
                                pageSize={pageSize}
                                getStorages={getStorages}
                                makeRequest={makeRequest}
                                goToProd={this.goToProd}
                            />
                        )}
                        <header className="content_header">
                            <div className="title_page">
                                {!!url
                                    ? breadcrumbs && breadcrumbs[breadcrumbs.length - 1] && breadcrumbs[breadcrumbs.length - 1].name && breadcrumbs[breadcrumbs.length - 1].name
                                    : "Популярные товары"}
                            </div>
                            {url && <FilterComponent handleFilter={this.handleFilter} reset={title} />}
                            {url && <SortComponent handleSort={this.handleSort} reset={title} />}
                        </header>
                        <div className="catalog_product">
                            {loading ? (
                                <p>Идет загрузка товара...</p>
                            ) : (
                                <Fragment>
                                    {products[!!url ? "category" : "popular"].length > 0 ? (
                                        products[!!url ? "category" : "popular"].map((el, idx) => {
                                            let sum = 0;
                                            el.storage_quantity &&
                                                el.storage_quantity.forEach(item => {
                                                    sum += item.quantity ? item.quantity : 0;
                                                });
                                            return (
                                                idx + 1 <= pageSize && (
                                                    <ShopPageCard
                                                        key={idx}
                                                        el={el}
                                                        idx={idx}
                                                        sum={sum}
                                                        goToProd={this.goToProd}
                                                        getStorages={getStorages}
                                                        productsStoragesName={!!url ? "products_results" : "popular_products"}
                                                        makeRequest={makeRequest}
                                                    />
                                                )
                                            );
                                        })
                                    ) : (
                                        <p>Нет товаров</p>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({ products, category }) => {
    return {
        products: {
            popular: products.popular_products,
            category: category.products_results
        },
        categoryProds: category.products,
        storages: products.storages,
        categories: category.all_categories,
        breadcrumbs: category.breadcrumbs,
        specialProducts: products.special_products
    };
};

const mapDispatchToProps = {
    getPopularProducts,
    getCategoryProducts,
    getBreadcrumbs,
    getStorages,
    makeRequest,
    getSpecialProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
