import React from "react";
import Loader from "../components/HelperComponents/Loader/Loader";

export function getOption(label) {
    return (
        <div className={`status `}>
            <div>
                {label !== "All networks" && <span />}
                {label}
            </div>
        </div>
    );
}

export function splitByCommas(data) {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function renderPageLoader(data) {
    return (
        <div className="loader_wrapper">
            <Loader size={40} />
        </div>
    );
}

export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
export function hashCode(str) {
    // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

export function intToRGB(i) {
    let c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}

//TYPES
//location, video, image, url, scan_qr, photo, new_qr, multiple, short_text, pictures, yes_no, rating, long_text, discount, bonus, bonus

export const renderStepPlaceholder = (name, type, length) => {
    if (name) return name;
    if (type === "location") {
        return "Visit this secret location";
    } else if (type === "video") {
        return "Watch this short video until the end";
    } else if (type === "image") {
        return "Watch this image for a short time";
    } else if (type === "url") {
        return "Visit this interesting site";
    } else if (type === "scan_qr") {
        return "Find and scan the QR code";
    } else if (type === "photo") {
        return "Take a photo of a bird";
    } else if (type === "new_qr") {
        return "Claim a QR code";
    } else if (type === "multiple") {
        return "What is new about the Big Tasty 2020 burger?";
    } else if (type === "short_text") {
        return "What did you like about the Big Tasty burger?";
    } else if (type === "pictures") {
        if (length < 2) {
            return "Do you like this image?";
        } else if (length === 2) {
            return "Which image do you like more?";
        } else {
            return "Which images do you like?";
        }
    } else if (type === "yes_no") {
        return "Did you like this product?";
    } else if (type === "rating") {
        return "How would you rate this product?";
    } else if (type === "discount") {
        return "You get -50% off this product!";
    } else if (type === "bonus") {
        return "Free boots with every 3 pair bought!";
    } else if (type === "monetary") {
        return "You get 25€ Campy money!";
    } else if (type === "new_barcode") {
        return "Claim a barcode";
    }
};

export const validateImage = file => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/x-icon"];
    return validTypes.indexOf(file.type) !== -1;
};

export function toFixedNoRounding(numb, n) {
    const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, "g");
    let a;
    try {
        a = numb.toString().match(reg)[0];
    } catch {
        if (isNaN(numb)) {
            a = "0.00".match(reg)[0];
        } else {
            a = `${numb}.00`.match(reg)[0];
        }
    }
    const dot = a.indexOf(".");

    if (dot === -1) {
        return a;
    }

    const b = n - (a.length - dot) + 1;

    return b > 0 ? a + "".repeat(b) : a;
}

export const changeArray = (id, storages, arr) => {
    let searchedElement = arr.find(el => el.id === id),
        index = arr.indexOf(searchedElement);
    // console.log(id, storages, arr)
    if (
        searchedElement &&
        searchedElement.storage_quantity &&
        searchedElement.storage_quantity.some(element =>
            storages.some(elem => element.storage_id === elem.storage_id && element.quantity !== elem.quantity)
        )
    ) {
        searchedElement.storage_quantity = storages;
    }
    arr[index] = searchedElement;

    return arr;
};

export const renameKeys = (keysMap, obj) =>
    Object.keys(obj).reduce(
        (acc, key) => ({
            ...acc,
            ...{ [keysMap[key] || key]: obj[key] }
        }),
        {}
    );

export const transformForSelect = (array, firstObj) => {
    const list = array ? array.map(item => renameKeys({ storage_id: "value", id: "value", name: "label" }, item)) : [];
    firstObj && list && list.unshift(firstObj);
    return list;
};

const storageList = [
    "Магазин Рассошенцы",
    "Магазин Левада",
    "Склад Левада",
    "Склад Юровка",
    "Великотирнівська 32, (ФОП)"
    // "Prorab Faмily",
    // "Prorab Family (Стемпицкий)"
];
export const storageFilter = (array, filterList = storageList) => {
    if (Array.isArray(array) && array.length === 0) {
        return [];
    }
    return array
        .filter(item => filterList.some(el => el === item.name))
        .sort((a, b) => {
            const first = a.name && a.name.toLowerCase();
            const second = b.name && b.name.toLowerCase();

            if (first > second) {
                return 1;
            }
            if (first < second) {
                return -1;
            }
            return 0;
        });
};

export const mergeArrays = (arrays, prop) => {
    const merged = {};

    arrays.forEach(arr => {
        arr.forEach(item => {
            merged[item[prop]] = Object.assign({}, merged[item[prop]], item);
        });
    });

    return Object.values(merged);
};

export const toNegativeNum = num => {
    if (typeof num === "number" && num > 0) {
        return Number(`-${num}`);
    }
    return 0;
};
