import React from "react";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import { ReactComponent as Adv1 } from '../../../assets/image/adv_1.svg';
import { ReactComponent as Adv2 } from '../../../assets/image/adv_2.svg';
import { ReactComponent as Adv3 } from '../../../assets/image/adv_3.svg';
import { ReactComponent as Adv4 } from '../../../assets/image/adv_4.svg';
import "./Advantages.scss";

const Advantages = () => {
    const advantagesList = [
        {
            ico: <Adv1 />,
            text: 'Комплектация под ключ и персональный менеджер'
        },
        {
            ico: <Adv2 />,
            text: 'Экспресс доставка'
        },
        {
            ico: <Adv3 />,
            text: 'Рассрочка до 15 мес по 0%'
        },
        {
            ico: <Adv4 />,
            text: 'Система лояльности “голд карта до 20% скидки”'
        },
    ]
    return <TransitionedBlock classes="transitioned_block">
        <div className="page_wrapper shop_page advantages-page">
            <div className="shop_content">
                <div className="title_page">Преимущества</div>
                <div className="advantages-content">
                    {advantagesList.map(({ ico, text }, idx) => (
                        <div className="advantage-block" key={idx}>
                            {ico}
                            <p className="advantage-text">{text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </TransitionedBlock>
}

export default Advantages;