import React from 'react';
import ReactStars from 'react-stars';

import './Rating.scss';

const Rating = ({value}) => {
    return (
        <div className="rating_wrapper">
            <ReactStars
                count={5}
                size={22}
                value={value}
                edit={false}
                color1="rgba(245, 166, 35, 0.2)"
                color2="#F5A623"
            />
        </div>
    );
};

export default Rating;