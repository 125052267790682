import React from "react";
import Spinner from "../../../assets/image/custom-spinner.svg";
import "./CustomSpinner.scss";

const CustomSpinner = ({ styles }) => {
    return (
        <div className="spinner-wrapper" style={styles}>
            <img src={Spinner} alt="spinner" />
        </div>
    );
};

export default CustomSpinner;
