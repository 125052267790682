import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setAdjustment } from "../../../actions/productsActions";
import { mergeArrays, storageFilter, toNegativeNum, transformForSelect } from "../../../helpers/functions";
import DefaultButton from "../../HelperComponents/DefaultButton/DefaultButton";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";
import { ReactComponent as MinusIcon } from "./icons/minus.svg";
import { ReactComponent as PlusIcon } from "./icons/plus.svg";
import "./AdjustmentDialog.scss";

const AdjustmentDialog = ({ open, onClose, productId, allStorages, storagesWithQuantity, setAdjustment }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const storageList = transformForSelect(mergeArrays([allStorages, storageFilter(storagesWithQuantity)], "name"));
    const [storage, setStorage] = useState();
    const onChangeStorage = event => {
        setStorage(event);
        setMinQuantity(event.quantity ? toNegativeNum(event.quantity) : 0);
        setQuantity(0);
    };

    const [minQuantity, setMinQuantity] = useState(-1);
    const [quantity, setQuantity] = useState(0);
    const onChangeQuantity = event => {
        let targetValue = event.target.value;
        let value;

        if (targetValue === "" || targetValue === " ") {
            value = "";
        } else {
            value = parseInt(targetValue);
        }
        if (targetValue >= minQuantity) {
            setQuantity(value);
        }
    };

    const handleQuantityValue = operation => () => {
        if (operation === "sub" && quantity > minQuantity) setQuantity(prevState => prevState - 1);
        if (operation === "add") setQuantity(prevState => prevState + 1);
    };

    const setDefaults = () => {
        let storageId = localStorage.getItem("storage_id");
        const defaultStorage = storageList.filter(item => item.value === Number(storageId));

        if (defaultStorage.length) {
            setStorage(defaultStorage[0]);
            setMinQuantity(
                defaultStorage[0] && defaultStorage[0].quantity ? toNegativeNum(defaultStorage[0].quantity) : 0
            );
        } else {
            setStorage(storageList[0]);
            setMinQuantity(storageList[0] && storageList[0].quantity ? toNegativeNum(storageList[0].quantity) : 0);
        }
    };

    useEffect(() => {
        setDefaults();
    }, [open]);

    const onSubmit = async () => {
        const { value } = storage;
        setLoading(true);
        const res = await setAdjustment(productId, value, quantity);
        if (res.payload) {
            setLoading(false);
            onCloseDialog();
        }
        if (res.error) {
            setLoading(false);
            setError("Произошла ошибка при отправке данных!");
        }
    };

    const onCloseDialog = () => {
        setStorage(null);
        setQuantity(0);
        setError("");
        onClose();
    };

    return (
        <DialogComponent className="adjustment_dialog" open={open} onClose={onCloseDialog}>
            <h2 className="adjustment_dialog__title">Корректировка остатков</h2>
            <div className="adjustment_dialog__columns">
                <div className="adjustment_dialog__select">
                    <span className="adjustment_dialog__label">Склад:</span>
                    <SelectComponent
                        value={storage}
                        options={storageList}
                        isClearable
                        isSearchable={false}
                        menuPortalTarget
                        change={onChangeStorage}
                    />
                </div>
                <div className="adjustment_dialog__quantity">
                    <label className="adjustment_dialog__label" htmlFor="quantity">
                        Количество товара:
                    </label>
                    <div className="quantity_input">
                        <button className="quantity_input__btn" type="button" onClick={handleQuantityValue("sub")}>
                            <span className="visually-hidden">Уменьшить</span>
                            <MinusIcon />
                        </button>
                        <input
                            id="quantity"
                            className="quantity_input__input"
                            value={quantity}
                            onChange={onChangeQuantity}
                        />
                        <button className="quantity_input__btn" type="button" onClick={handleQuantityValue("add")}>
                            <span className="visually-hidden">Увеличить</span>
                            <PlusIcon />
                        </button>
                    </div>
                </div>
            </div>

            {error && <p className="adjustment_dialog__error">{error}</p>}

            <div className="adjustment_dialog__btn-group">
                <DefaultButton
                    className="adjustment_dialog__btn"
                    loading={loading}
                    disabled={loading}
                    onClick={onSubmit}
                >
                    Сохранить
                </DefaultButton>
                <DefaultButton className="adjustment_dialog__btn" classes="contained_grey" onClick={onCloseDialog}>
                    Отменить
                </DefaultButton>
            </div>
        </DialogComponent>
    );
};

const mapStateToProps = ({ products }) => {
    return {
        storagesWithQuantity: products.storages
    };
};

const mapDispatchToProps = {
    setAdjustment
};

export default connect(mapStateToProps, mapDispatchToProps)(AdjustmentDialog);
