export const CATEGORIES = {
    GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
    GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
    GET_ALL_CATEGORIES_FAIL: "GET_ALL_CATEGORIES_FAIL",

    GET_CATEGORIES: "GET_CATEGORIES",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAIL: "GET_CATEGORIES_FAIL",

    GET_BREADCRUMBS: "GET_BREADCRUMBS",
    GET_BREADCRUMBS_SUCCESS: "GET_BREADCRUMBS_SUCCESS",
    GET_BREADCRUMBS_FAIL: "GET_BREADCRUMBS_FAIL",

    CATEGORIES_GET_STORAGES: "CATEGORIES_GET_STORAGES",
    CATEGORIES_GET_STORAGES_SUCCESS: "CATEGORIES_GET_STORAGES_SUCCESS"
};

export const PRODUCTS = {
    GET_POPULAR_PRODUCTS: "GET_POPULAR_PRODUCTS",
    GET_POPULAR_PRODUCTS_SUCCESS: "GET_POPULAR_PRODUCTS_SUCCESS",
    GET_SPECIAL_PRODUCTS: "GET_SPECIAL_PRODUCTS",
    GET_SPECIAL_PRODUCTS_SUCCESS: "GET_SPECIAL_PRODUCTS_SUCCESS",
    GET_POPULAR_PRODUCTS_FAIL: "GET_POPULAR_PRODUCTS_FAIL",
    GET_CERTAIN_PRODUCT: "GET_CERTAIN_PRODUCT",
    GET_CERTAIN_PRODUCT_SUCCESS: "GET_CERTAIN_PRODUCT_SUCCESS",
    GET_CERTAIN_PRODUCT_FAIL: "GET_CERTAIN_PRODUCT_FAIL",
    GET_RELATED_PRODUCTS: "GET_RELATED_PRODUCTS",
    GET_RELATED_PRODUCTS_SUCCESS: "GET_RELATED_PRODUCTS_SUCCESS",
    PRODUCTS_GET_STORAGES: "PRODUCTS_GET_STORAGES",
    PRODUCTS_GET_STORAGES_SUCCESS: "PRODUCTS_GET_STORAGES_SUCCESS",
    MAKE_REQUEST: "MAKE_REQUEST",
    REQUEST_NEW_PRODUCT: "REQUEST_NEW_PRODUCT",
    SET_ADJUSTMENT: "SET_ADJUSTMENT"
};

export const AUTH = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    HELPER: "HELPER",
    GET_PERSONAL_INFO: "GET_PERSONAL_INFO",
    GET_PERSONAL_INFO_SUCCESS: "GET_PERSONAL_INFO_SUCCESS",
    GET_PERSONAL_INFO_FAIL: "GET_PERSONAL_INFO_FAIL",
    GET_STORAGES_AUTH: "GET_STORAGES_AUTH",
    CLIENT: {},
    GET_DROP_DATA: "GET_DROP_DATA",
    GET_DROP_DATA_SUCCESS: "GET_DROP_DATA_SUCCESS",
    GET_DROP_SHOP: "GET_DROP_SHOP",
    GET_DROP_SHOP_SUCCESS: "GET_DROP_SHOP_SUCCESS"
};

export const CLIENT = {
    PHONE_CHECK: "PHONE_CHECK",
    PHONE_CHECK_SUCCESS: "PHONE_CHECK_SUCCESS",
    PHONE_CHECK_FAIL: "PHONE_CHECK_FAIL",
    REGISTRATION: "REGISTRATION",
    LOG_OUT: "LOG_OUT",
    GET_CLIENT_INFO: "GET_CLIENT_INFO",
    GET_CLIENT_INFO_SUCCESS: "GET_CLIENT_INFO_SUCCESS",
    GET_CLIENT_INFO_FAIL: "GET_CLIENT_INFO_FAIL",
    SEND_TO_PICKING: "SEND_TO_PICKING",
    SEND_ADVERTISING_CHANNEL: "SEND_ADVERTISING_CHANNEL",
    GET_ADVERTISING_CHANNEL: "GET_ADVERTISING_CHANNEL",
    CART_UPDATE: "CART_UPDATE",
    SET_NEED_DELIVERY: "SET_NEED_DELIVERY",
    SET_ADDRESS: "SET_ADDRESS",
    SET_DATE: "SET_DATE"
};

export const SEARCH = {
    SEARCH: "SEARCH",
    SEARCH_SUCCESS: "SEARCH_SUCCESS",
    SEARCH_FAIL: "SEARCH_FAIL",
    SEARCH_ALL: "SEARCH_ALL",
    SEARCH_ALL_SUCCESS: "SEARCH_ALL_SUCCESS",
    SEARCH_ALL_FAIL: "SEARCH_ALL_FAIL",
    SEARCH_GET_STORAGES: "SEARCH_GET_STORAGES",
    SEARCH_GET_STORAGES_SUCCESS: "SEARCH_GET_STORAGES_SUCCESS"
};

export const CODE = {
    CODE: "CODE",
    CODE_SUCCESS: "CODE_SUCCESS",
    CODE_FAIL: "CODE_FAIL"
};
