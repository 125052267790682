import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import CategoriesReducer from './reduceCategories';
import ProductsReducer from './reduceProducts';
import AuthReducer from './reduceAuth';
import SearchReducer from './reduceSearch';
import ClientReducer from './reduceClientAuth';
import {reducer as formReducer} from 'redux-form';

const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        category: CategoriesReducer,
        products: ProductsReducer,
        auth: AuthReducer,
        search: SearchReducer,
        client: ClientReducer,
    });

export default rootReducer;
