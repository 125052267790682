import { AUTH } from "../constants";

export function login(data) {
    return {
        type: AUTH.LOGIN,
        payload: {
            client: "default",
            request: {
                url: `/tablet/signin/`,
                method: "POST",
                data
            }
        }
    };
}

export function logout() {
    return {
        type: AUTH.LOGOUT,
        payload: {
            client: "default",
            request: {
                url: `/logout/`,
                method: "POST"
            }
        }
    };
}

export function getStoragesAuth() {
    return {
        type: AUTH.GET_STORAGES_AUTH,
        payload: {
            client: "default",
            request: {
                url: `/tablet/storages/`,
                method: "GET"
            }
        }
    };
}

export function personalInfo() {
    return {
        type: AUTH.GET_PERSONAL_INFO,
        payload: {
            client: "default",
            request: {
                url: `/profile/personal-info/name/`,
                method: "GET"
            }
        }
    };
}

export function sendMail(data) {
    return {
        type: AUTH.HELPER,
        payload: {
            client: "default",
            request: {
                url: `/tablet/password-recovery/send-email/`,
                method: "POST",
                data
            }
        }
    };
}

export function checkCode(data) {
    return {
        type: AUTH.HELPER,
        payload: {
            client: "default",
            request: {
                url: `/tablet/password-recovery/check-code/`,
                method: "POST",
                data
            }
        }
    };
}

export function passRecovery(data) {
    return {
        type: AUTH.HELPER,
        payload: {
            client: "default",
            request: {
                url: `/password-recovery/`,
                method: "POST",
                data
            }
        }
    };
}

export function getHeaderDropData() {
    return {
        type: AUTH.GET_DROP_DATA,
        payload: {
            client: "default",
            request: {
                url: `/tablet/manager/performance/`,
                method: "GET"
            }
        }
    };
}

export function getHeaderDropShop(id) {
    return {
        type: AUTH.GET_DROP_SHOP,
        payload: {
            client: "default",
            request: {
                url: `/tablet/manager/performance/?storage_id=${id}`,
                method: "GET"
            }
        }
    };
}
