import React, { Component } from "react";
import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import { Link } from "react-router-dom";
import arrow_back from "../../../../assets/image/arrow_back.svg";
import logo_auth from "../../../../assets/image/logo_auth.svg";
import {Field, reduxForm, SubmissionError} from "redux-form";
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../HelperComponents/DefaultButton/DefaultButton";
import {connect} from "react-redux";

import {checkCode} from '../../../../actions/authActions';

class SecondStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recoveryMail: localStorage.getItem('recovery_mail'),
        };
    }

    submitForm = data => {
        const {history, checkCode} = this.props;
        const {recoveryMail} = this.state;
        data.email = recoveryMail;
        return checkCode(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.removeItem('recovery_mail');
                localStorage.setItem('security_token', res.payload.data.security_token);
                history.push('/auth/password-recovery');
            } else if (res.error) {
                throw new SubmissionError({ code: 'Код введен неверно' });
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;
        const { recoveryMail } = this.state;

        return (
            <TransitionedBlock>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="back_block">
                        <Link className="good_hover" to={`/auth/login`}>
                            <img src={arrow_back} alt="arrow_back" />
                            Вернуться к авторизации
                        </Link>
                    </div>
                    <div className="logo_block_auth">
                        <img src={logo_auth} alt="logo_auth" />
                    </div>
                    <div className="title_auth">Код восстановления</div>
                    <div className="descriptions_auth">Код восстановления пароля отправлен <br/> <span>на <strong> {recoveryMail}</strong></span> </div>
                    <div>
                        <Field name="code" type="text" component={RenderField} label="Код восстановления пароля"/>
                    </div>
                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            disabled={submitting || pristine || !valid}
                            formAction
                        >
                            Продолжить
                        </DefaultButton>
                    </div>
                </form>

            </TransitionedBlock>
        );
    }
}

export const validate = values => {
    const errors = {};
    if (!values.code) {
        errors.code = "Обязательное поле";
    } else if (values.code.length !== 6) {
        errors.code = "Должно быть 6 цифр";
    }
    return errors;
};

SecondStep = reduxForm({
    form: "SecondStep",
    validate
})(SecondStep);

const mapDispatchToProps = {
    checkCode
};

export default connect(null, mapDispatchToProps)(SecondStep);


