import { CLIENT } from "../constants";

const INITIAL_STATE = {
    code: "",
    client_phone: "",
    client_info: {
        first_name: "Безымянный",
        last_name: "Безымянный",
        email: "sample@some-mail.com",
        phone_number: "+380XXXXXXXXX",
        user_discount: 0
    },
    cart_update: 0,
    cart: [],
    needDelivery: false,
    deliveryAddress: "",
    selectedDate: null
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CLIENT.PHONE_CHECK_SUCCESS:
            return {
                ...state,
                code: action.payload.data.code,
                client_phone: action.payload.data.phone_number
            };
        case CLIENT.PHONE_CHECK_FAIL:
            localStorage.setItem("client_phone", JSON.parse(action.error.config.data).phone_number);
            return {
                ...state,
                client_phone: JSON.parse(action.error.config.data).phone_number
            };
        case CLIENT.GET_CLIENT_INFO_SUCCESS:
            localStorage.setItem("client_info", JSON.stringify(action.payload.data));

            return {
                ...state
                //client_info: action.payload.data,
            };
        case CLIENT.CART_UPDATE:
            return {
                ...state,
                cart_update: state.cart_update + 1,
                cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
            };
        case CLIENT.SET_NEED_DELIVERY:
            return {
                ...state,
                needDelivery: action.payload
            };
        case CLIENT.SET_ADDRESS:
            return {
                ...state,
                deliveryAddress: action.payload
            };
        case CLIENT.SET_DATE:
            return {
                ...state,
                selectedDate: action.payload
            };
        default:
            return state;
    }
}
