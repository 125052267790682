let BASE_URL, SECOND_URL;

if (window.location.host === "localhost:3000" || window.location.host === "localhost:3001"
    || window.location.host === "localhost:3002"
    || window.location.host.includes("prorab-helper-app")) {
    BASE_URL = "https://api.prorab-new.4-com.pro/v0";
} else {
    BASE_URL = "https://api.prorab.group/v0";
}

SECOND_URL = "";

export const API_BASE_URL = BASE_URL;
export const API_SECOND_URL = SECOND_URL;
