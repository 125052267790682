import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { ReactComponent as SortIcon } from "./icons/sort.svg";
import "./SortComponent.scss";

const SortComponent = ({ handleSort, reset }) => {
    const list = [
        { value: "availability", label: "В наличии" },
        { value: "price", label: "Цена по возрастанию" },
        { value: "-price", label: "Цена по убыванию" },
        { value: "name", label: "Название" },
        { value: "promotional", label: "Акционные" },
        { value: "top_sales", label: "Топ продаж" }
    ];
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const [radioValue, setRadioValue] = useState(list[0]);
    const onSelectValue = (e) => {
        setRadioValue({ value: e.target.value, label: e.target.id });
        setOpen(false);
    };

    useEffect(() => {
        handleSort(radioValue.value);
    }, [radioValue]);

    useEffect(() => {
        setRadioValue(list[0]);
    }, [reset]);

    return (
        <div className="sort_wrap">
            <button className="sort_wrap__btn" type="button" onClick={onOpen}>
                <SortIcon />
                <span>{radioValue.label}</span>
            </button>

            {open && (
                <ClickAwayListener onClickAway={onClose}>
                    <div className="sort_wrap__popover">
                        <button className="sort_wrap__btn popover_btn" type="button" onClick={onClose}>
                            <SortIcon />
                            <span>Сортировать:</span>
                        </button>
                        <ul className="sort_wrap__list">
                            {list && list.map(({ value, label }) => (
                                <li key={value} className="sort_wrap__item">
                                    <input
                                        id={label}
                                        type="radio"
                                        name="sort"
                                        value={value}
                                        onChange={onSelectValue}
                                        checked={radioValue.value === value}
                                    />
                                    <label htmlFor={label}>{label}</label>
                                </li>
                            ))}
                        </ul>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default SortComponent;
