import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import RenderField from "../HelperComponents/RenderField/RenderField";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import { Link } from "react-router-dom";
import arrow_back from "../../assets/image/arrow_back.svg";
import "./Client.scss";
import CircularProgress from "@material-ui/core/CircularProgress";

import SelectComponent from "../HelperComponents/SelectComponent/SelectComponent";
import { getOption } from "../../helpers/functions";

import { registration, getAdvChan, GetClientInfo } from "../../actions/clientAuthActions";

class ClientRegistration extends Component {
    constructor(props) {
        super(props);
        //localStorage.getItem('regForm') ? this.options.find(el => el.value === JSON.parse(localStorage.getItem('regForm')).advertising_channel) : null
        this.state = {
            buttonLoad: localStorage.getItem("buttonLoad") || "false",
            advertising_channel: null,
            segment: null,
            channels: [],
            segments: [],
            errors: {}
        };
    }

    componentDidMount() {
        this.getInfo("channel");
        this.getInfo("segment");
    }

    getInfo = type => {
        const { getAdvChan } = this.props;
        getAdvChan(type).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.setState({
                    [`${type}s`]: res.payload.data.map((el, idx) => ({ label: getOption(el), value: el, id: idx }))
                });
                if (localStorage.getItem("regForm") && type === "channel") {
                    this.setState(({ channels }) => {
                        const value = channels
                            ? channels.find(
                                el => el.value === JSON.parse(localStorage.getItem("regForm")).advertising_channel
                            )
                            : null;
                        return {
                            advertising_channel: value
                        };
                    });
                }
            }
        });
    };

    handleChange = name => event => {
        let { errors } = this.state;
        if (errors.hasOwnProperty(name)) delete errors[name];
        this.setState({ [name]: event, errors: { ...errors } });
    };

    submitForm = data => {
        const { registration, GetClientInfo, client_phone, history } = this.props;
        const { advertising_channel, segment } = this.state;
        localStorage.setItem("buttonLoad", "true");
        this.setState({ buttonLoad: "true" });
        data.phone_number = localStorage.getItem('client_phone');
        data.advertising_channel = advertising_channel ? advertising_channel.value ? advertising_channel.value : '' : '';
        data.segment = segment ? segment.value ? segment.value : '' : '';
        data.first_name = data.first_name.replace(/\s+/g, " ").trim();
        data.last_name = data.last_name.replace(/\s+/g, " ").trim();
        data.storage_id = localStorage.getItem("storage_id");
        if (data.email) data.email = data.email.replace(/\s+/g, " ").trim();
        localStorage.setItem("regForm", JSON.stringify(data));
        return registration(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                GetClientInfo(data.phone_number).then(res => {
                    if (res.payload && res.payload.status && res.payload.status === 200) {
                        localStorage.setItem("buttonLoad", "false");
                        this.setState({ buttonLoad: "false" });
                        localStorage.setItem("client_phone", client_phone);
                        localStorage.setItem("is_logged", "true");
                        localStorage.removeItem("regForm");
                        history.push("/main/client/info");
                    } else {
                        localStorage.removeItem("client_phone");
                    }
                });
            } else {
                console.log(res.error.response.data);
                localStorage.setItem("buttonLoad", "false");
                this.setState({ buttonLoad: "false", errors: res.error.response.data });

                throw new SubmissionError({
                    ...res.error.response.data,
                    _error: res.error.response.data.phone_number || res.error.response.data.detail
                });
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid, error } = this.props;
        const { buttonLoad, advertising_channel, channels, segment, segments, errors } = this.state;
        return (
            <TransitionedBlock classes="transitioned_block">
                <div className="client_page registration">
                    <div className="back_block">
                        <Link
                            className="good_hover"
                            onClick={() => localStorage.removeItem("regForm")}
                            to={`/main/client/login`}
                        >
                            <img src={arrow_back} alt="arrow_back" />
                            Вернуться к авторизации клиента
                        </Link>
                    </div>
                    <div className={`client_content${buttonLoad === "true" ? " waiting" : ""}`}>
                        {buttonLoad === "true" && (
                            <div className={`loader`}>
                                <CircularProgress color="secondary" />
                            </div>
                        )}
                        <div className="title">Регистрация клиента</div>

                        <form onSubmit={handleSubmit(this.submitForm)}>
                            <div>
                                <Field name="first_name" type="text" component={RenderField} label="Имя*" />
                            </div>

                            <div>
                                <Field name="last_name" type="text" component={RenderField} label="Фамилия*" />
                            </div>
                            <div>
                                <Field name="email" type="text" component={RenderField} label="Электронная почта" />
                            </div>
                            <div className={`advertising_channel`}>
                                <SelectComponent
                                    value={advertising_channel}
                                    options={channels}
                                    change={this.handleChange("advertising_channel")}
                                    isClearable="false"
                                    isSearchable={false}
                                    placeholder="Откуда узнали о нас*"
                                />
                                {errors.advertising_channel &&
                                    <span
                                        className={`storage_error`}
                                        style={{ marginBottom: "10px", display: "block", color: "red", fontSize: "12px" }}
                                    >
                                        {errors.advertising_channel}
                                    </span>
                                }
                            </div>
                            <div className={`advertising_channel`}>
                                <SelectComponent
                                    value={segment}
                                    options={segments}
                                    change={this.handleChange("segment")}
                                    isClearable="false"
                                    isSearchable={false}
                                    placeholder="Сегмент Клиента*"
                                />
                                {errors.segment &&
                                    <span
                                        className={`storage_error`}
                                        style={{ marginBottom: "10px", display: "block", color: "red", fontSize: "12px" }}
                                    >
                                        {errors.segment}
                                    </span>
                                }
                            </div>
                            {error && (
                                <span
                                    className={`storage_error`}
                                    style={{ marginBottom: "10px", display: "block", color: "red", fontSize: "12px" }}
                                >
                                    {error}
                                </span>
                            )}
                            <DefaultButton
                                variant="contained"
                                /* disabled={
                                    submitting //  || pristine
                                    || !valid ||
                                    buttonLoad === "true" ||
                                    advertising_channel === null ||
                                    segment === null
                                } */
                                formAction
                                classes={`registration-progress`}
                            /* type="link"
                    to="/main/client/info" */
                            >
                                {buttonLoad === "true" && <CircularProgress color="secondary" />}
                                Регистрация клиента
                            </DefaultButton>
                        </form>
                    </div>
                </div>
            </TransitionedBlock>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!!values.email) {
        //errors.email = "Обязательное поле";
        if (
            !values.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i) &&
            !values.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}\s/i)
        ) {
            errors.email = "Неправильный email";
        }
    }
    if (!values.password) {
        errors.password = "Обязательное поле";
    } else if (values.password.length < 8) {
        errors.password = "Минимум 8 символов";
    }
    if (!values.first_name) {
        errors.first_name = "Обязательное поле";
    } else if (values.first_name.match(/[^a-zA-ZА-Яа-яЁёіІїЇєЄґҐ\s]/g)) {
        errors.first_name = "Имя может состоять только из букв";
    }
    if (!values.last_name) {
        errors.last_name = "Обязательное поле";
    } else if (values.last_name.match(/[^a-zA-ZА-Яа-яЁёіІїЇєЄґҐ\s]/g)) {
        errors.last_name = "Фамилия может состоять только из букв";
    }
    return errors;
};

ClientRegistration = reduxForm({
    form: "ClientRegistrationForm",
    validate
})(ClientRegistration);

const mapStateToProps = ({ client }) => {
    return {
        client_phone: client.client_phone,
        initialValues: {
            ...JSON.parse(localStorage.getItem("regForm"))
        }
    };
};

const mapDispatchToProps = {
    registration,
    getAdvChan,
    GetClientInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientRegistration);
