import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import TransitionedBlock from "../../../HelperComponents/TransitionedBlock/TransitionedBlock";
import RenderField from "../../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../../HelperComponents/DefaultButton/DefaultButton";
import {Link} from "react-router-dom";
import arrow_back from "../../../../assets/image/arrow_back.svg";
import logo_auth from "../../../../assets/image/logo_auth.svg";

import {passRecovery} from '../../../../actions/authActions';

class ThirdStep extends Component {
    constructor(props) {
        super(props);
        this.state = {
            security_token: localStorage.getItem('security_token'),
        };
    }

    submitForm = data => {
        const {history, passRecovery} = this.props;
        const {security_token} = this.state;
        delete data.confirm_password;
        data.security_token = security_token;
        return passRecovery(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.clear();
                localStorage.setItem('temp_token', res.payload.data.token);
                history.push('/auth/reset-password/fourth-step');
            } else if (res.error) {
                throw new SubmissionError({ ...res.error.response.data });
            }
        });
    };

    render() {
        const { handleSubmit, submitting, pristine, valid } = this.props;

        return (
            <TransitionedBlock>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="back_block">
                        <Link className="good_hover" to={`/auth/login`}>
                            <img src={arrow_back} alt="arrow_back" />
                            Вернуться к авторизации
                        </Link>
                    </div>
                    <div className="logo_block_auth">
                        <img src={logo_auth} alt="logo_auth" />
                    </div>
                    <div className="title_auth">Восстановление пароля</div>
                    <div>
                        <Field name="password" type="password" component={RenderField} label="Новый пароль"/>
                    </div>
                    <div>
                        <Field name="confirm_password" type="password" component={RenderField} label="Подтвердите новый пароль"/>
                    </div>
                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            disabled={submitting || pristine || !valid}
                            formAction
                        >
                            Продолжить
                        </DefaultButton>
                    </div>
                </form>

            </TransitionedBlock>
        );
    }
}

const validate = values => {
    const errors = {};
        if (!values.password) {
            errors.password = "Обязательное поле";
        } else if (values.password.length < 8) {
            errors.password = "Минимум 8 символов"
        }
        if (!values.confirm_password) {
            errors.confirm_password = "Обязательное поле";
        } else if (values.confirm_password.length < 8) {
            errors.confirm_password = "Минимум 8 символов";
        } else if (values.confirm_password !== values.password) {
            errors.confirm_password = "Пароли не совпадают";
        }
    return errors;
};

ThirdStep = reduxForm({
    form: "ThirdStep",
    validate
})(ThirdStep);

function mapStateToProps(state) {
    return {
    };
}
const mapDispatchToProps = {
    passRecovery
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdStep);
