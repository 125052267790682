import React, { Component, Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../../components/Header/Header";
//import Footer from "../../components/Footer/Footer";
import ShopPage from "../../components/ShopPage/ShopPage";
import SearchPage from "../../components/SearchPage/SearchPage";
import ProductRequest from "../../components/ProductRequest/ProductRequest";
import ProductInner from "../../components/ProductInner/ProductInner";
import AuthClient from "../../components/Client/AuthClient";
import ClientFound from "../../components/Client/ClientFound";
import ClientNotFound from "../../components/Client/ClientNotFound";
import ClientRegistration from "../../components/Client/ClientRegistration";
import ClientInfo from "../../components/Client/ClientInfo";
import Advantages from "../../components/Info/Advantages/Advantages";
import Camera from "../../components/Camera/Camera";


class Container extends Component {

    render() {
        const {match, history, location} = this.props;
        if (!localStorage.token) return <Redirect to="/auth/login" />;
        return (
            <Fragment>
                <div className="page">
                    <Header history={history} loc={location.pathname} />
                    <div className="wrapper">
                        <Switch>
                            <Route path={match.url} exact render={() => <Redirect to="/main/shop" />} />
                            <Route path={`${match.url}/camera`} exact component={Camera} />
                            <Route path={`${match.url}/shop`} exact component={ShopPage} />
                            <Route path={`${match.url}/info/advantages`} exact component={Advantages} />
                            <Route path={`${match.url}/shop/search/:searchValue`} exact component={SearchPage} />
                            <Route path={`${match.url}/shop/product/:slug`} exact component={ProductInner} />
                            <Route path={`${match.url}/product`} exact component={ProductRequest} />
                            <Route path={`${match.url}/client/login`} exact component={AuthClient} />
                            <Route path={`${match.url}/client/found`} exact component={ClientFound} />
                            <Route path={`${match.url}/client/not-found`} exact component={ClientNotFound} />
                            <Route path={`${match.url}/client/registration`} exact component={ClientRegistration} />
                            <Route path={`${match.url}/client/info`} exact component={ClientInfo} />
                            <Route render={() => <p>Not found</p>} />
                        </Switch>
                    </div>
                    {/*<Footer history={history} loc={location.pathname} />*/}
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = () => {
    return {


    };
};
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
