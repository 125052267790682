import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../../components/Auth/Login/Login";
import FirstStep from "../../components/Auth/PasswordRecovery/FirstStep/FirstStep";
import SecondStep from "../../components/Auth/PasswordRecovery/SecondStep/SecondStep";
import ThirdStep from "../../components/Auth/PasswordRecovery/ThirdStep/ThirdStep";
import FourthStep from "../../components/Auth/PasswordRecovery/FourthStep/FourthStep";
import bg_auth from "../../assets/image/bg_auth.svg";

import "./AuthContainer.scss";

const AuthContainer = props => {
    const { match } = props;
    if (!!localStorage.token) return <Redirect to="/main" />;
    return (
        <Fragment>
            <main className="auth_container">
                <div className="auth-box">
                    <div className="auth_bg">
                        <img src={bg_auth} alt="bg_auth" />
                    </div>
                    <div className="auth_content">
                        <Switch>
                            <Route path={`${match.url}/login`} exact component={Login} />
                            <Route path={`${match.url}/reset-password/first-step`} exact component={FirstStep} />
                            <Route path={`${match.url}/reset-password/second-step`} exact component={SecondStep} />
                            <Route path={`${match.url}/reset-password/fourth-step`} exact component={FourthStep} />
                            <Route path={`${match.url}/password-recovery`} component={ThirdStep} />

                            <Route render={() => <p>Not found</p>} />
                        </Switch>
                    </div>
                </div>
            </main>
        </Fragment>
    );
};

export default AuthContainer;
