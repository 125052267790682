import React, { useEffect, useState } from "react";
import { ClickAwayListener } from "@material-ui/core";
import { ReactComponent as FilterIcon } from "./icons/filter.svg";
import "./FilterComponent.scss";

const FilterComponent = ({ handleFilter, reset }) => {
    const list = [
        { value: "promotional", label: "Акционный" },
        { value: "sale", label: "Распродажа" },
        { value: "top_sales", label: "Топ продаж" }
    ];
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);

    const [select, setSelect] = useState([]);
    const onSelect = (e) => {
        const { target: { value } } = e;
        const idx = select.findIndex(el => el === value);

        if (idx === -1) {
            setSelect((prev) => ([...prev, value]));
        } else {
            setSelect((prev) => ([...prev.slice(0, idx), ...prev.slice(idx + 1)]));
        }
    };

    const onClearAll = () => setSelect([]);

    useEffect(() => {
        handleFilter(select);
    }, [select]);

    useEffect(() => {
        onClearAll();
    }, [reset]);

    return (
        <div className="filter_wrap">
            <button className="filter_wrap__btn" type="button" onClick={onOpen}>
                <FilterIcon />
                <span>Фильтр</span>
                {select.length > 0 && <span className="filter_wrap__count">+{select.length}</span>}
            </button>

            {select.length > 0 && (
                <button className="filter_wrap__clear_btn" type="button" onClick={onClearAll}>
                    <span>Очистить все</span>
                </button>
            )}

            {open && (
                <ClickAwayListener onClickAway={onClose}>
                    <div className="filter_wrap__popover">
                        <button className="filter_wrap__btn popover_btn" type="button" onClick={onClose}>
                            <FilterIcon />
                            <span>Показывать:</span>
                        </button>
                        <ul className="filter_wrap__list">
                            {list && list.map(({ value, label }) => (
                                <li key={value} className="filter_wrap__item">
                                    <input
                                        id={value}
                                        type="checkbox"
                                        value={value}
                                        onChange={onSelect}
                                        checked={select.includes(value)}
                                    />
                                    <label htmlFor={value}>{label}</label>
                                </li>
                            ))}
                        </ul>
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};

export default FilterComponent;
