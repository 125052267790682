import { PRODUCTS } from '../constants';

export function requestNewProd(data) {
    return {
        type: PRODUCTS.REQUEST_NEW_PRODUCT,
        payload: {
            client: 'default',
            request: {
                url: `/tablet/new-product/request/`,
                method: "POST",
                data
            }
        }
    };
}